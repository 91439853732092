import React, { useState, useEffect } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Table from "@amzn/awsui-components-react/polaris/table";
import Button from "@amzn/awsui-components-react/polaris/button";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Link from "@amzn/awsui-components-react/polaris/link";
import Box from "@amzn/awsui-components-react/polaris/box";
import Tabs from "@amzn/awsui-components-react/polaris/tabs";
import { CSVLink } from "react-csv";
import ApiFactory from '../../open-api/ApiFactory';
import { RoomAccessRecord, Scan } from '../../open-api/generated-src';
import { PhoneToolImg, scanStatus, typeStatus } from "../Helper";
import Badge from "@amzn/awsui-components-react/polaris/badge";

interface EmptyStateProps {
    title: string;
    subtitle: string;
    action: React.ReactNode;
}

const EmptyState = ({ title, subtitle, action }: EmptyStateProps) => (
    <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
            {title}
        </Box>
        <Box variant="p" padding="m" color="inherit">
            {subtitle}
        </Box>
        {action}
    </Box>
);

const RoomAccessRecordList = () => {
    const [activeTabId, setActiveTabId] = useState("roomAccess");
    const [roomAccessRecords, setRoomAccessRecords] = useState<RoomAccessRecord[]>([]);
    const [scanRecords, setScanRecords] = useState<Scan[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const Api = ApiFactory();

    useEffect(() => {
        fetchRecords();
    }, [activeTabId]);

    const fetchRecords = async () => {
        setLoading(true);
        try {
            if (activeTabId === "roomAccess") {
                const response = await Api.listRoomAccessRecords();
                setRoomAccessRecords(response.data.records);
            } else {
                const response = await Api.listScans();
                setScanRecords(response.data.scans);
            }
            setError(null);
        } catch (err) {
            setError('Failed to fetch records');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const roomAccessColumnDefinitions = [
        {
                    id: "operator",
                    header: "操作人员",
                    cell: item => {
                        const operator = item.operator;
                        // 分离操作人员名称和设备信息
                        const match = operator.match(/^(.*?)(?:\s*\((.*?)\))?$/);
                        if (match) {
                            const [, name, devices] = match;
                            return (
                                <SpaceBetween direction="vertical" size="xs">
                                    <SpaceBetween direction="horizontal" size="xs">
                                        {name}
                                        {PhoneToolImg(name)}
                                    </SpaceBetween>
                                    {devices && <Box color="text-status-info">{devices}</Box>}
                                </SpaceBetween>
                            );
                        }
                        return operator;
                    }
                },
        {
            id: "securityGuard",
            header: "安保人员",
            cell: item => item.securityGuard,
            sortingField: "securityGuard"
        },
        {
            id: "accessType",
            header: "进出类型",
            cell: item => (
                <Badge color={
                    item.accessType === 'PERSON-ONLY' ? 'blue' :
                        item.accessType === 'WITH-SPARE' ? 'green' :
                            'red'
                }>
                    {item.accessType === 'PERSON-ONLY' ? '人员进出' :
                        item.accessType === 'WITH-SPARE' ? '携带备件' :
                            '携带工具'}
                </Badge>
            ),
            sortingField: "accessType"
        },
        {
            id: "roomInfo",
            header: "房间信息",
            cell: item => item.roomInfo,
            sortingField: "roomInfo"
        },
        {
            id: "status",
            header: "进出状态",
            cell: item => (
                <Badge color={item.status === 'IN' ? 'green' : 'blue'}>
                    {item.status === 'IN' ? '进入' : '出去'}
                </Badge>
            ),
            sortingField: "status"
        },
        {
            id: "serialNumberOrModel",
            header: "设备型号/序列号",
            cell: item => item.serialNumberOrModel || '-',
            sortingField: "serialNumberOrModel"
        },
        {
            id: "tickets",
            header: "Tickets",
            cell: item => item.tickets || '-',
            sortingField: "tickets"
        },
        {
            id: "checkTime",
            header: "检查时间",
            cell: item => item.checkTime,
            sortingField: "checkTime"
        },
        {
                    id: "checkResult",
                    header: "检查结果",
                    cell: item => {
                        const result = item.checkResult;
                        if (result.startsWith('APPROVED')) {
                            return <Box color="text-status-success">{result}</Box>;
                        } else if (result.startsWith('REJECTED')) {
                            return <Box color="text-status-error">{result}</Box>;
                        } else {
                            return <Box color="text-status-info">待处理</Box>;
                        }
                    }
                }
    ];

    const scanColumnDefinitions = [
        {
            id: 'bb_login',
            header: '操作人员',
            cell: (item: Scan) => (
                <SpaceBetween direction="horizontal" size="xs">
                    {item.bb_login}
                    {PhoneToolImg(item.bb_login)}
                </SpaceBetween>
            ),
            sortingField: 'bb_login'
        },
        {
            id: 'guard_login',
            header: '安保人员',
            cell: item => item.guard_login,
            sortingField: 'guard_login'
        },
        {
            id: 'type',
            header: '类型',
            cell: item => typeStatus(item.type as string),
            sortingField: 'type'
        },
        {
            id: 'room',
            header: '房间及进出',
            cell: item => item.room,
            sortingField: 'room'
        },
        {
            id: 'tickets',
            header: 'Tickets',
            cell: item => item.tickets || '-',
            sortingField: 'tickets'
        },
        {
            id: 'sn',
            header: '序列号/型号',
            cell: item => item.sn,
            sortingField: 'sn'
        },
        {
            id: 'scan_deta',
            header: '扫描时间',
            cell: item => item.scan_deta,
            sortingField: 'scan_deta'
        },
        {
            id: 'status',
            header: '扫描结果',
            cell: item => scanStatus(item.status as string),
            sortingField: 'status'
        }
    ];

    // Room Access Records Collection
    const roomAccessCollection = useCollection(roomAccessRecords, {
        filtering: {
            empty: <EmptyState title="No records" subtitle="No records to display." action={null} />,
            noMatch: <EmptyState title="No matches" subtitle="We can't find a match." action={null} />,
        },
        pagination: { pageSize: 20 },
        sorting: {
            defaultState: {
                sortingColumn: roomAccessColumnDefinitions[7], // 使用检查时间列作为默认排序
                isDescending: true,
            }
        },
    });

    // Scan Records Collection
    const scanCollection = useCollection(scanRecords, {
        filtering: {
            empty: <EmptyState title="No records" subtitle="No records to display." action={null} />,
            noMatch: <EmptyState title="No matches" subtitle="We can't find a match." action={null} />,
        },
        pagination: { pageSize: 50 },
        sorting: {
            defaultState: {
                sortingColumn: scanColumnDefinitions[6], // 使用扫描时间列作为默认排序
                isDescending: true,
            }
        },
    });

    const csvData = activeTabId === "roomAccess" ?
        roomAccessRecords.map(record => ({
            操作人员: record.operator,
            安保人员: record.securityGuard,
            进出类型: record.accessType === 'PERSON-ONLY' ? '人员进出' :
                record.accessType === 'WITH-SPARE' ? '携带备件' :
                    '携带工具',
            房间信息: record.roomInfo,
            进出状态: record.status === 'IN' ? '进入' : '出去',
            设备型号或序列号: record.serialNumberOrModel || '-',
            Tickets: record.tickets || '-',
            检查时间: record.checkTime,
            检查结果: record.checkResult === 'APPROVED' ? '允许' : '拒绝'
        })) :
        scanRecords.map(record => ({
            操作人员: record.bb_login,
            安保人员: record.guard_login,
            类型: record.type,
            房间及进出: record.room,
            Tickets: record.tickets || '-',
            序列号型号: record.sn,
            扫描时间: record.scan_deta,
            扫描结果: record.status
        }));

    return (
        <ContentLayout
            header={
                <SpaceBetween size="m">
                    <Header
                        variant="h1"
                        info={<Link>Info</Link>}
                        description="机房进出记录管理系统"
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={fetchRecords} iconName="refresh">
                                    刷新
                                </Button>
                                <CSVLink
                                    data={csvData}
                                    filename={`${activeTabId}_records.csv`}
                                    className="awsui-button awsui-button-variant-primary"
                                >
                                    导出 CSV
                                </CSVLink>
                            </SpaceBetween>
                        }
                    >
                        机房进出记录
                    </Header>
                    <Tabs
                        tabs={[
                            {
                                label: "机房进出记录",
                                id: "roomAccess",
                                content: (
                                    <Table
                                        {...roomAccessCollection.collectionProps}
                                        columnDefinitions={roomAccessColumnDefinitions}
                                        items={roomAccessCollection.items}
                                        loading={loading}
                                        loadingText="Loading records"
                                        header={
                                            <Header
                                                counter={`(${roomAccessRecords.length})`}
                                                actions={
                                                    <TextFilter
                                                        {...roomAccessCollection.filterProps}
                                                        countText={`Results: ${roomAccessCollection.filteredItemsCount}`}
                                                        filteringPlaceholder="查找记录"
                                                    />
                                                }
                                            />
                                        }
                                        pagination={<Pagination {...roomAccessCollection.paginationProps} />}
                                    />
                                )
                            },
                            {
                                label: "工具检查记录",
                                id: "toolCheck",
                                content: (
                                    <Table
                                        {...scanCollection.collectionProps}
                                        columnDefinitions={scanColumnDefinitions}
                                        items={scanCollection.items}
                                        loading={loading}
                                        loadingText="Loading records"
                                        header={
                                            <Header
                                                counter={`(${scanRecords.length})`}
                                                actions={
                                                    <TextFilter
                                                        {...scanCollection.filterProps}
                                                        countText={`Results: ${scanCollection.filteredItemsCount}`}
                                                        filteringPlaceholder="查找记录"
                                                    />
                                                }
                                            />
                                        }
                                        pagination={<Pagination {...scanCollection.paginationProps} />}
                                    />
                                )
                            }
                        ]}
                        activeTabId={activeTabId}
                        onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
                    />
                </SpaceBetween>
            }
        >
            {error && <Box color="text-status-error">{error}</Box>}
        </ContentLayout>
    );
};

export default RoomAccessRecordList;