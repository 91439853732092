import React, { useState, useEffect } from 'react';
import SideNavigation, {SideNavigationProps} from '@amzn/awsui-components-react/polaris/side-navigation';
import BreadcrumbGroup, {BreadcrumbGroupProps} from '@amzn/awsui-components-react/polaris/breadcrumb-group';
import {useHistory, useLocation} from 'react-router-dom';
import ApiFactory from '../../open-api/ApiFactory';
import { getUser } from "../../auth/User";
import Badge from "@amzn/awsui-components-react/polaris/badge";

const HOME = {text: 'BJS CICO Assistants', href: '#/'};

const ALLOWED_USERS = [
    'annazha', 'baochenx', 'boxu', 'carlsun', 'cylan', 'fengchaw', 'haipengx', 'haiyany',
    'hejiale', 'henrhong', 'hnke', 'jianfeil', 'junyzeng', 'lijunju', 'ltaol', 'lwenyue',
    'michtian', 'onesong', 'pengbin', 'sophyang', 'sukvi', 'szhongka', 'xinxux', 'xinyuzha',
    'xiupengw', 'xudongsh', 'xzzhang', 'yachigao', 'qiguowe'
];

export function Breadcrumbs({items}: { items: BreadcrumbGroupProps.Item[] }) {
    return <BreadcrumbGroup items={[HOME, ...items]}/>;
}

export function ServiceNavigation() {
    const location = useLocation();
    const navigate = useHistory();
    const [hasPendingReviews, setHasPendingReviews] = useState(false);
    const [hasPermission, setHasPermission] = useState(false);

    useEffect(() => {
        const checkPermissionAndPendingReviews = async () => {
            try {
                const currentUser = await getUser();
                const userHasPermission = ALLOWED_USERS.includes(currentUser);
                setHasPermission(userHasPermission);

                if (userHasPermission) {
                    const Api = ApiFactory();
                    const response = await Api.listPendingReviewRecords();
                    setHasPendingReviews(response.data.records.length > 0);
                }
            } catch (error) {
                console.error("Error checking permission or pending reviews:", error);
            }
        };

        checkPermissionAndPendingReviews();
    }, []);

    const items: SideNavigationProps.Item[] = [
        {
            type: 'section',
            text: '验证',
            items: [
                {type: 'link', text: '主页', href: '#/home'},
                {type: 'link', text: '机房进出登记', href: '#/scan'},
                {type: 'link', text: '备件图片', href: '#/spare/list'},
                {type: 'link', text: 'CAT', href: '#/cat'},
            ]
        },
        {
            type: 'section',
            text: 'Admin',
            items: hasPermission ? [
                {
                    type: 'link', 
                    text: '待审核记录', 
                    href: '#/pending-review',
                    info: hasPendingReviews ? <Badge color="blue">NEW</Badge> : undefined
                },
            ] : []
        },
        {
            type: 'section',
            text: '其他',
            items: [
                {type: 'link', text: 'CICO Exception', href: '#/exception'},
                {type: 'link', text: '记录查询和导出', href: '#/listrecords'},
            ]
        }
    ];

    const onFollowHandler = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
        event.preventDefault();
        if (event.detail.href) {
            window.location.href = event.detail.href;
        }
    };

    return <SideNavigation header={HOME} items={items} activeHref={`#${location.pathname}`}
                           onFollow={onFollowHandler}/>;
}