import * as React from "react";
import { useState, useEffect, useRef } from 'react';
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import RadioGroup from "@amzn/awsui-components-react/polaris/radio-group";
import { AttributeEditor, Checkbox, ColumnLayout } from "@amzn/awsui-components-react";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import ApiFactory from '../../open-api/ApiFactory';
import { getUser } from "../../auth/User";
import { PhoneToolImg, cicoNotes, cicoStatus, mitigationsStatus } from "../Helper";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import ProgressBar from "@amzn/awsui-components-react/polaris/progress-bar";
import Select from "@amzn/awsui-components-react/polaris/select";
import { BaseKeyDetail } from "@amzn/awsui-components-react/polaris/internal/events";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Markdown from "react-markdown";
import { Ede } from "../../open-api/generated-src";

interface RecordData {
    operator: string;
    securityGuard: string;
    accessType: 'PERSON-ONLY' | 'WITH-SPARE' | 'WITH-TOOL';
    roomInfo: string;
    status: 'IN' | 'OUT';
    tickets: string;
    serialNumberOrModel: string;
    checkTime: string;
    checkResult: string;
    sealItems?: string[];
    tpvr?: string;
}

interface SparePartImage {
    imageUrl: string;
    model: string;
    type: string;
    updateTime: string;
    updatedBy: string;
    approvedBy: string;
}

interface BulkResult {
    serialNumber: string;
    info: any;
    image: SparePartImage | null;
    error: string | null;
    checkResult: 'APPROVED' | 'REJECTED';
}

interface Tool {
    modelNumber: string;
    category: string;
    maker: string;
    definitions?: string;
    mitigations?: string;
    notes?: string;
    approved: string;
    imageUrl?: string;
}

export default () => {
    // Refs
    const operatorInputRef = useRef<HTMLInputElement>(null);
    const roomInfoInputRef = useRef<HTMLInputElement>(null);
    const serialNumberInputRef = useRef<HTMLInputElement>(null);
    const ticketsInputRef = useRef<HTMLInputElement>(null);

    const [hasYubico, setHasYubico] = useState(true);
    const [hasIntercom, setHasIntercom] = useState(true);

    // Basic States
    const [user, setUser] = useState('');
    const [errorText, setErrorText] = useState('');
    const [infoText, setInfoText] = useState("请依次输入信息");
    const [infoType, setInfoType] = useState<"error" | "success" | "info" | "warning">("info");
    const [showOperatorImage, setShowOperatorImage] = useState(false);

    // Tool and Spare Part States
    const [sparePartImage, setSparePartImage] = useState<any>(null);
    const [allTools, setAllTools] = useState<Tool[]>([]);
    const [isCatTool, setIsCatTool] = useState(false);
    const [catToolData, setCatToolData] = useState<Tool | null>(null);
    const [sealItems, setSealItems] = useState<string[]>([]);
    const [tpvr, setTPVRData] = useState('');

    // Modal States
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isVisibleexce, setVisibleexce] = useState(false);
    const [visible, setVisible] = useState(false);
    const [isBulkModalVisible, setIsBulkModalVisible] = useState(false);

    // EDE Related States
    const [edeData, setEdeData] = useState<Ede>({
        model: "Loading",
        category: "Loading",
        maker: "Loading",
        notes: "Loading",
        status: "Loading",
        updateat: "Loading",
        updateby: "Loading",
        approvedby: "Loading",
        edeId: "Loading"
    });
    const [statusData, setStatusData] = useState<React.ReactNode>(<p>Pending</p>);
    const [noteData, setNoteData] = useState<React.ReactNode>(<p>Pending</p>);
    const [appData, setAppData] = useState<React.ReactNode>(<p>Pending</p>);
    const [ssealData, setSsealData] = useState<React.ReactNode>(<p>Pending</p>);
    const [mitigationStatus, setMitigationStatus] = useState<React.ReactNode>(<p>Pending</p>);
    const [srcUrl, setSrcUrl] = useState('');

    // Bulk Operation States
    const [bulkSerialNumbers, setBulkSerialNumbers] = useState('');
    const [bulkResults, setBulkResults] = useState<BulkResult[]>([]);
    const [bulkOperator, setBulkOperator] = useState('');
    const [bulkRoomInfo, setBulkRoomInfo] = useState('');
    const [bulkStatus, setBulkStatus] = useState<'IN' | 'OUT'>('IN');
    const [bulkTickets, setBulkTickets] = useState('');
    const [validationProgress, setValidationProgress] = useState(0);

    // Type Selection States
    const [typeOptions, setTypeOptions] = useState<{ label: string; value: string }[]>([]);
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [selectedTypeImage, setSelectedTypeImage] = useState<SparePartImage | null>(null);

    // Record States
    const [localRecords, setLocalRecords] = useState<any[]>([]);
    const [isPartUnderCustody, setIsPartUnderCustody] = useState(true);
    const [partInfo, setPartInfo] = useState<any>(null);
    // Initialize
    const Api = ApiFactory();
    const ScansApi = ApiFactory();

    const initialRecordData: RecordData = {
        operator: '',
        securityGuard: user,
        accessType: 'PERSON-ONLY',
        roomInfo: '',
        status: 'IN',
        tickets: '',
        serialNumberOrModel: '',
        checkTime: new Date().toISOString(),
        checkResult: 'PENDING',
        sealItems: [],
        tpvr: ''
    };

    const [recordData, setRecordData] = useState<RecordData>(initialRecordData);

    const handleBulkValidation = async () => {
        if (!bulkOperator || !bulkRoomInfo || !selectedType) {
            setErrorText('请填写必要信息并选择备件类型');
            return;
        }
    
        const serialNumbers = bulkSerialNumbers.split('\n').filter(sn => sn.trim());
        const results: BulkResult[] = [];
        
        setValidationProgress(0);
    
        for (let i = 0; i < serialNumbers.length; i++) {
            const sn = serialNumbers[i];
            try {
                const response = await ScansApi.checkSN(sn.trim(), bulkOperator);
                const result: BulkResult = {
                    serialNumber: sn.trim(),
                    info: response.data,
                    image: null,
                    error: null,
                    checkResult: 'APPROVED' // 默认设置为允许
                };
    
                if (response.data && response.data['type_name']) {
                    const type_name = response.data['type_name'].replace(/\s+/g, '-');
                    const model_name = response.data['model_name'].replace(/\s+/g, '-');
    
                    if (type_name !== selectedType) {
                        result.error = `备件类型不匹配，期望: ${selectedType}，实际: ${type_name}`;
                        result.checkResult = 'REJECTED';
                    } else if (response.data['messages'] === 'Parts Not In Mobility') {
                        result.error = 'Parts Not In Mobility';
                        result.checkResult = 'REJECTED';
                    } else {
                        const imageResponse = await Api.listSparePartImages();
                        const approvedImages = imageResponse.data.sparePartImages.filter(isFullyApproved);
    
                        let matchingImage = approvedImages.find(
                            img => img.type === type_name && (img.model === model_name || img.model === type_name)
                        );
    
                        if (matchingImage) {
                            result.image = matchingImage;
                        }
                    }
                } else {
                    result.error = '备件不存在';
                    result.checkResult = 'REJECTED';
                }
    
                results.push(result);
            } catch (error) {
                results.push({
                    serialNumber: sn.trim(),
                    info: null,
                    image: null,
                    error: "查询失败",
                    checkResult: 'REJECTED'
                });
            }
    
            setValidationProgress(((i + 1) / serialNumbers.length) * 100);
        }
    
        setBulkResults(results);
    };

    // Effects
    useEffect(() => {
        (async () => {
            try {
                setUser(await getUser());
            } catch (error: any) {
                setUser('Unknown User')
            }
        })();
    }, []);

    useEffect(() => {
        const fetchAllTools = async () => {
            try {
                const response = await ScansApi.listTools(undefined, 800);
                setAllTools(response.data.tools);
            } catch (error) {
                console.error("Error fetching tools:", error);
            }
        };

        fetchAllTools();
    }, []);

    useEffect(() => {
        const fetchTypeOptions = async () => {
            try {
                const response = await Api.listSparePartImages();
                const approvedImages = response.data.sparePartImages.filter(isFullyApproved);
                const uniqueTypes = [...new Set(approvedImages.map(img => img.type))];
                setTypeOptions(uniqueTypes.map(type => ({ label: type, value: type })));
            } catch (error) {
                console.error("Error fetching type options:", error);
            }
        };

        fetchTypeOptions();
    }, []);

    // Helper Functions
    const isFullyApproved = (image: any) => {
        const approvers = image.approvedBy ? image.approvedBy.split(',').filter(Boolean) : [];
        return approvers.length >= 2;
    };

    const handleTypeSelection = async (selectedType: string) => {
        setSelectedType(selectedType);
        try {
            const imageResponse = await Api.listSparePartImages();
            const approvedImages = imageResponse.data.sparePartImages.filter(isFullyApproved);
            const matchingImage = approvedImages.find(img => img.type === selectedType && img.model === selectedType);
            setSelectedTypeImage(matchingImage || null);
        } catch (error) {
            console.error("Error fetching type image:", error);
            setSelectedTypeImage(null);
        }
    };

    // Event Handlers
    const onChange = (attribute: string, value: string) => {
        const newState = { ...recordData };
        newState[attribute] = value;
        setRecordData(newState);
    };

    const handleKeyDown = (attribute: string, event: CustomEvent<BaseKeyDetail>) => {
        if (event.detail.key === 'Enter') {
            switch (attribute) {
                case 'operator':
                    setShowOperatorImage(true);
                    if (roomInfoInputRef.current) roomInfoInputRef.current.focus();
                    break;
                case 'roomInfo':
                    if (serialNumberInputRef.current) serialNumberInputRef.current.focus();
                    break;
                case 'serialNumberOrModel':
                    if (recordData.accessType === 'WITH-SPARE' || recordData.accessType === 'WITH-TOOL') {
                        checkSparePartImage(recordData.serialNumberOrModel, recordData.operator);
                    }
                    if (ticketsInputRef.current) ticketsInputRef.current.focus();
                    break;
                case 'tickets':
                    handleSubmit();
                    break;
            }
        }
    };

    // Main Functions
    const checkSparePartImage = async (serialNumber: string, operator: string) => {
        if (recordData.accessType !== 'WITH-SPARE') return;
        try {
            const partInfoResponse = await ScansApi.checkSN(serialNumber, operator);
            setPartInfo(partInfoResponse.data);

            if (partInfoResponse.data['messages'] === "Parts Not Under Custody") {
                setIsPartUnderCustody(false);
                setInfoType("warning");
                setInfoText("备件不在监管之下，请确保输入了有效的 Ticket");
            } else {
                setIsPartUnderCustody(true);
            }

            if (!partInfoResponse.data || !partInfoResponse.data['type_name']) {
                setSparePartImage(null);
                setInfoType("error");
                setInfoText("无法获取备件信息");
                return;
            }

            const type_name = partInfoResponse.data['type_name'].replace(/\s+/g, '-');
            const model_name = partInfoResponse.data['model_name'].replace(/\s+/g, '-');

            const response = await Api.listSparePartImages();
            const approvedImages = response.data.sparePartImages.filter(isFullyApproved);

            let matchingImage = approvedImages.find(
                img => img.type === type_name && (img.model === model_name || img.model === type_name)
            );

            if (matchingImage) {
                setSparePartImage(matchingImage);
            } else {
                setSparePartImage(null);
                setInfoType("error");
                setInfoText(`未找到类型为 ${type_name} 的已批准备件图片`);
            }
        } catch (error) {
            console.error("Error checking spare part image:", error);
            setSparePartImage(null);
            setInfoType("error");
            setInfoText("查询备件图片时发生错误");
        }
    };
    const turnOnModal = async (test: string) => {
        // 重置所有状态
        setEdeData({
            model: "Loading",
            category: "Loading",
            maker: "Loading",
            notes: "Loading",
            status: "Loading",
            updateat: "Loading",
            updateby: "Loading",
            approvedby: "Loading",
            edeId: "Loading"
        });
        setIsCatTool(false);
        setCatToolData(null);
        setErrorText('');
        setStatusData(<p>Pending</p>);
        setNoteData(<p>Pending</p>);
        setAppData(<p>Pending</p>);
        setSsealData(<p>Pending</p>);
        setMitigationStatus(<p>Pending</p>);
        setSrcUrl('');

        try {
            if (recordData.accessType === 'WITH-TOOL') {
                // 首先在已获取的工具列表中查找匹配的工具
                const matchedTool = allTools.find(tool =>
                    (tool.modelNumber.toLowerCase() === test.toLowerCase() ||
                        tool.category.toLowerCase() === test.toLowerCase() ||
                        tool.maker.toLowerCase() === test.toLowerCase()) &&
                    tool.approved === "Approved"
                );

                if (matchedTool) {
                    // 如果在 CAT 中找到匹配的已批准工具
                    setIsCatTool(true);
                    setCatToolData(matchedTool);
                    setVisible(true);
                    return;
                }

                // 如果在 CAT 中没找到，检查 EDE
                const response = await ScansApi.edeGet(test);
                if (response.data as string === 'Not in EDE/Exception') {
                    setErrorText('Not in CAT/Exception');
                    return;
                }

                const edeDate = (await ScansApi.getEde(test)).data.ede as Ede;
                setEdeData(edeDate);

                if (edeDate.status.indexOf('CICO') !== -1) {
                    // CICO Exception 处理
                    setNoteData(cicoNotes(edeDate.notes, 'notes'));
                    setAppData(cicoNotes(edeDate.notes, 'approve'));
                    setSsealData(cicoNotes(edeDate.notes, 'seal'));

                    const str = edeDate.status.split('- S:', 2)[1];
                    const start = new Date(str.split('=E:', 2)[0]).getTime() / 1000;
                    const end = new Date(str.split('=E:', 2)[1]).getTime() / 1000;

                    const approve_seal = edeDate.notes.split('&&Approve ID&&', 2)[1];
                    const seal = approve_seal.split('&&Seal&&', 2)[1];

                    const date = new Date().getTime() / 1000;

                    if ((start - date) <= 0 && (end - date) >= 0 &&
                        sealItems.join(',') === seal) {
                        setMitigationStatus(mitigationsStatus(edeDate.status));
                        setVisibleexce(true);
                    } else {
                        setErrorText('CICO Exception过期或安防标签不匹配！');
                    }
                } else {
                    setVisible(true);
                }

                setStatusData(cicoStatus(edeData.status));
                const edeid = edeDate.edeId + '.png';
                await ScansApi.getPic(edeid).then(res => {
                    setSrcUrl(res.data as string);
                });
            } else if (recordData.accessType === 'WITH-SPARE') {
                // 备件验证逻辑
                const edeDate = (await ScansApi.getEde(test)).data.ede as Ede;
                setEdeData(edeDate);
                setVisible(true);

                setStatusData(cicoStatus(edeData.status));
                const edeid = edeDate.edeId + '.png';
                await ScansApi.getPic(edeid).then(res => {
                    setSrcUrl(res.data as string);
                });
            }
        } catch (error) {
            console.error("Error in turnOnModal:", error);
            setErrorText('验证失败');
        }
    };

    const handleSubmit = async () => {
        setErrorText('');
        try {
            if (!recordData.operator || !recordData.roomInfo) {
                setErrorText('请填写必要信息');
                return;
            }

            if (recordData.accessType === 'WITH-SPARE') {
                if (!recordData.serialNumberOrModel) {
                    setErrorText('带入设备时必须填写设备型号');
                    return;
                }
                await checkSparePartImage(recordData.serialNumberOrModel, recordData.operator);

                if (!isPartUnderCustody && !recordData.tickets) {
                    setErrorText('备件不在监管之下，必须填写 Ticket');
                    return;
                }
                setIsModalVisible(true);
            } else if (recordData.accessType === 'WITH-TOOL') {
                if (!recordData.serialNumberOrModel) {
                    setErrorText('必须填写工具型号或序列号');
                    return;
                }

                // 直接调用 turnOnModal 进行工具验证
                await turnOnModal(recordData.serialNumberOrModel);
            } else {
                // 人员进出直接显示确认 Modal
                setIsModalVisible(true);
            }
        } catch (error: any) {
            setErrorText(error?.response?.data?.message || '创建记录失败');
        }
    };

    const handleModalSubmit = async (checkResult) => {
        try {
            // 为所有类型添加设备信息
            let operatorInfo = recordData.operator;
            const devices: string[] = [];
            if (hasYubico) devices.push('YUBICO');
            if (hasIntercom) devices.push('对讲机');
            if (devices.length > 0) {
                operatorInfo = `${operatorInfo} (${devices.join(', ')})`;
            }

            let finalCheckResult = checkResult;
            if (partInfo) {
                if (partInfo['messages'] === 'Parts Not Under Custody') {
                    finalCheckResult = `P${checkResult}(Parts Not Under Custody)`;
                } else if (partInfo['messages'] === 'Parts Not In Mobility') {
                    finalCheckResult = `P${checkResult}(Parts Not In Mobility)`;
                }
            }
    
            const requestContent = {
                ...recordData,
                operator: operatorInfo,
                securityGuard: user,
                checkTime: new Date().toISOString(),
                checkResult: finalCheckResult,
                sealItems: recordData.accessType === 'WITH-TOOL' ? sealItems : undefined,
                tpvr: recordData.accessType === 'WITH-TOOL' ? tpvr : undefined
            };
    
            const response = await Api.createRoomAccessRecord(requestContent);
            setLocalRecords(prev => [response.data.record, ...prev]);
    
            setInfoType("success");
            setInfoText(`记录创建成功 - ${finalCheckResult}`);
            
            // Reset form
            setRecordData({
                ...recordData,
                serialNumberOrModel: '',
                tickets: '',
                checkResult: 'PENDING'
            });
            setSealItems([]);
            setTPVRData('');
            setHasYubico(true);
            setHasIntercom(true);
    
            if (serialNumberInputRef.current) {
                serialNumberInputRef.current.focus();
            }
    
            setIsModalVisible(false);
        } catch (error: any) {
            setErrorText(error?.response?.data?.message || '创建记录失败');
        }
    };

    const handleCheckResultChange = (index: number, value: 'APPROVED' | 'REJECTED') => {
        setBulkResults(prevResults => {
            const newResults = [...prevResults];
            newResults[index].checkResult = value;
            return newResults;
        });
    };


    const handleBulkSubmit = async () => {
        for (const result of bulkResults) {
            if (!result.error) {
                const requestContent = {
                    operator: bulkOperator,
                    securityGuard: user,
                    accessType: 'WITH-SPARE',
                    roomInfo: bulkRoomInfo,
                    status: bulkStatus,
                    tickets: bulkTickets,
                    serialNumberOrModel: result.serialNumber,
                    checkTime: new Date().toISOString(),
                    checkResult: result.checkResult
                };

                try {
                    const response = await Api.createRoomAccessRecord(requestContent);
                    setLocalRecords(prev => [response.data.record, ...prev]);
                } catch (error: any) {
                    setErrorText(error?.response?.data?.message || '创建记录失败');
                }
            }
        }

        setIsBulkModalVisible(false);
        setInfoType("success");
        setInfoText("批量记录创建成功");
    };


    // Render helpers
    const renderValue = (value: unknown): React.ReactNode => {
        if (typeof value === 'string') {
            if (value === 'Parts Not Under Custody' || value === 'Parts Not In Mobility') {
                return <Box color="text-status-error">{value}</Box>;
            }
            return value;
        }
        return String(value);
    };

    // Column definitions for tables
    const columnDefinitions = [
        {
            id: "operator",
            header: "操作人员",
            cell: item => {
                const operator = item.operator;
                // 分离操作人员名称和设备信息
                const match = operator.match(/^(.*?)(?:\s*\((.*?)\))?$/);
                if (match) {
                    const [, name, devices] = match;
                    return (
                        <SpaceBetween direction="vertical" size="xs">
                            <SpaceBetween direction="horizontal" size="xs">
                                {name}
                                {PhoneToolImg(name)}
                            </SpaceBetween>
                            {devices && <Box color="text-status-info">{devices}</Box>}
                        </SpaceBetween>
                    );
                }
                return operator;
            }
        },
        {
            id: "securityGuard",
            header: "安保人员",
            cell: item => item.securityGuard
        },
        {
            id: "accessType",
            header: "进出类型",
            cell: item => {
                switch (item.accessType) {
                    case 'PERSON-ONLY':
                        return '人员进出';
                    case 'WITH-SPARE':
                        return '携带备件';
                    case 'WITH-TOOL':
                        return '工具进出';
                    default:
                        return item.accessType;
                }
            }
        },
        {
            id: "roomInfo",
            header: "房间信息",
            cell: item => item.roomInfo
        },
        {
            id: "status",
            header: "进出状态",
            cell: item => item.status === 'IN' ? '进入' : '出去'
        },
        {
            id: "serialNumberOrModel",
            header: "设备型号/序列号",
            cell: item => item.serialNumberOrModel || '-'
        },
        {
            id: "tickets",
            header: "Tickets",
            cell: item => item.tickets || '-'
        },
        {
            id: "checkTime",
            header: "检查时间",
            cell: item => item.checkTime
        },
        {
            id: "checkResult",
            header: "检查结果",
            cell: item => {
                const result = item.checkResult;
                if (result.startsWith('PAPPROVED')) {
                    return <Box color="text-status-success">{result}</Box>;
                } else if (result.startsWith('PREJECTED')) {
                    return <Box color="text-status-error">{result}</Box>;
                } else {
                    return <Box color="text-status-info">待处理</Box>;
                }
            }
        }
    ];

    // Main render
    return (
        <>
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button formAction="none" variant="link" href="#/room-access">
                            取消
                        </Button>
                        <Button variant="primary" onClick={handleSubmit}>
                            提交
                        </Button>
                    </SpaceBetween>
                }
                header={
                    <>
                        <Header variant="h1">机房进出登记</Header>
                        <Header variant="h3">安保人员：{user}</Header>
                    </>
                }
                errorText={errorText}
            >
                <Container>
                    <Alert
                        statusIconAriaLabel="Info"
                        type={infoType}
                        header={infoText}
                    />
                    <SpaceBetween direction="vertical" size="l">
                    <ColumnLayout columns={2}>
    <FormField 
        label={
            <span>
                操作人员 {showOperatorImage && recordData.operator && PhoneToolImg(recordData.operator)}
            </span>
        } 
        description="进入人员的登录名"
    >
        <Input
            ref={operatorInputRef}
            value={recordData.operator}
            onChange={({ detail }) => {
                onChange('operator', detail.value);
                setShowOperatorImage(false);
            }}
            onKeyDown={(e) => handleKeyDown('operator', e)}
            placeholder="请输入操作人员ID"
        />
    </FormField>
    <FormField label="进出类型">
        <RadioGroup
            onChange={({ detail }) => onChange('accessType', detail.value as 'PERSON-ONLY' | 'WITH-SPARE' | 'WITH-TOOL')}
            value={recordData.accessType}
            items={[
                { value: 'PERSON-ONLY', label: '人员进出' },
                { value: 'WITH-SPARE', label: '携带备件' },
                { value: 'WITH-TOOL', label: '工具进出' }
            ]}
        />
    </FormField>
    <FormField label="必备设备">
        <SpaceBetween direction="horizontal" size="xs">
            <Checkbox
                checked={hasYubico}
                onChange={({ detail }) => setHasYubico(detail.checked)}
                description="安全认证设备"
            >
                YUBICO
            </Checkbox>
            <Checkbox
                checked={hasIntercom}
                onChange={({ detail }) => setHasIntercom(detail.checked)}
                description="通讯设备"
            >
                对讲机
            </Checkbox>
        </SpaceBetween>
    </FormField>
    <FormField label="房间信息">
        <Input
            ref={roomInfoInputRef}
            value={recordData.roomInfo}
            onChange={({ detail }) => onChange('roomInfo', detail.value)}
            onKeyDown={(e) => handleKeyDown('roomInfo', e)}
            placeholder="请输入房间信息"
        />
    </FormField>
                            <FormField label="进出状态">
                                <RadioGroup
                                    onChange={({ detail }) => onChange('status', detail.value as 'IN' | 'OUT')}
                                    value={recordData.status}
                                    items={[
                                        { value: 'IN', label: '进入' },
                                        { value: 'OUT', label: '出去' }
                                    ]}
                                />
                            </FormField>
                            {(recordData.accessType === 'WITH-SPARE' || recordData.accessType === 'WITH-TOOL') && (
                                <>
                                    <FormField label="设备型号/序列号">
                                        <Input
                                            ref={serialNumberInputRef}
                                            value={recordData.serialNumberOrModel}
                                            onChange={({ detail }) => onChange('serialNumberOrModel', detail.value)}
                                            onKeyDown={(e) => handleKeyDown('serialNumberOrModel', e)}
                                            placeholder="请输入设备型号或序列号"
                                        />
                                    </FormField>
                                    <FormField label="Tickets">
                                        <Input
                                            ref={ticketsInputRef}
                                            value={recordData.tickets}
                                            onChange={({ detail }) => onChange('tickets', detail.value)}
                                            onKeyDown={(e) => handleKeyDown('tickets', e)}
                                            placeholder="请输入相关Ticket"
                                        />
                                    </FormField>
                                </>
                            )}
                            {recordData.accessType === 'WITH-TOOL' && (
                                <>
                                    <FormField label="封签号">
                                        <AttributeEditor
                                            onRemoveButtonClick={({ detail: { itemIndex } }) => {
                                                const tmpItems = [...sealItems];
                                                tmpItems.splice(itemIndex, 1);
                                                setSealItems(tmpItems);
                                            }}
                                            items={sealItems}
                                            addButtonText="添加标签"
                                            definition={[
                                                {
                                                    label: "Seal",
                                                    control: (item, index) => (
                                                        <Input
                                                            value={sealItems[index]}
                                                            placeholder="请输入标签"
                                                            onChange={({ detail }) => {
                                                                const tmpItems = [...sealItems];
                                                                tmpItems[index] = detail.value;
                                                                setSealItems(tmpItems);
                                                            }}
                                                            autoFocus
                                                        />
                                                    )
                                                }
                                            ]}
                                            onAddButtonClick={() => setSealItems([...sealItems, ""])}
                                            empty="无安防标签"
                                            removeButtonText="删除"
                                        />
                                    </FormField>
                                    <FormField label="携带人/陪同人">
                                        <Input
                                            value={tpvr}
                                            placeholder='BB login'
                                            onChange={({ detail: { value } }) => setTPVRData(value)}
                                        />
                                    </FormField>
                                </>
                            )}
                        </ColumnLayout>

                        {sparePartImage && (
                            <Container header={<Header variant="h2">已批准的备件图片信息</Header>}>
                                <Alert type="success" header="此备件图片已完全批准">
                                    该备件已获得至少两人的批准。
                                </Alert>
                                <img
                                    src={sparePartImage.imageUrl}
                                    alt="Approved Spare Part"
                                    style={{ maxWidth: '100%', maxHeight: '300px' }}
                                />
                                <Table
                                    columnDefinitions={[
                                        { header: "型号", cell: item => item.model },
                                        { header: "类型", cell: item => item.type },
                                        { header: "更新时间", cell: item => item.updateTime },
                                        { header: "更新人", cell: item => item.updatedBy },
                                        { header: "批准人", cell: item => item.approvedBy }
                                    ]}
                                    items={[sparePartImage]}
                                />
                            </Container>
                        )}
                    </SpaceBetween>
                </Container>
            </Form>

            <Button onClick={() => setIsBulkModalVisible(true)}>
                批量验证
            </Button>

            <Modal
                onDismiss={() => setIsBulkModalVisible(false)}
                visible={isBulkModalVisible}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setIsBulkModalVisible(false)}>关闭</Button>
                            <Button variant="primary" onClick={handleBulkValidation}>验证</Button>
                            {bulkResults.length > 0 && (
                                <Button variant="primary" onClick={handleBulkSubmit}>提交</Button>
                            )}
                        </SpaceBetween>
                    </Box>
                }
                header="批量验证备件"
            >
                <SpaceBetween direction="vertical" size="l">
                    <FormField label="操作人员">
                        <Input
                            value={bulkOperator}
                            onChange={({ detail }) => setBulkOperator(detail.value)}
                            placeholder="请输入操作人员ID"
                        />
                    </FormField>
                    <FormField label="房间信息">
                        <Input
                            value={bulkRoomInfo}
                            onChange={({ detail }) => setBulkRoomInfo(detail.value)}
                            placeholder="请输入房间信息"
                        />
                    </FormField>
                    <FormField label="进出状态">
                        <RadioGroup
                            onChange={({ detail }) => setBulkStatus(detail.value as 'IN' | 'OUT')}
                            value={bulkStatus}
                            items={[
                                { value: 'IN', label: '进入' },
                                { value: 'OUT', label: '出去' }
                            ]}
                        />
                    </FormField>
                    <FormField label="Tickets">
                        <Input
                            value={bulkTickets}
                            onChange={({ detail }) => setBulkTickets(detail.value)}
                            placeholder="请输入相关Ticket"
                        />
                    </FormField>
                    <FormField label="备件类型">
                        <Select
                            selectedOption={selectedType ? { label: selectedType, value: selectedType } : null}
                            onChange={({ detail }) => handleTypeSelection(detail.selectedOption.value as string)}
                            options={typeOptions}
                            placeholder="选择备件类型"
                        />
                    </FormField>

                    {selectedTypeImage && (
                        <Container header={<Header variant="h2">已批准的备件图片</Header>}>
                            <img 
                                src={selectedTypeImage.imageUrl} 
                                alt="Approved Spare Part" 
                                style={{maxWidth: '100%', maxHeight: '300px'}} 
                            />
                            <Table
                                columnDefinitions={[
                                    { header: "型号", cell: item => item.model },
                                    { header: "类型", cell: item => item.type },
                                    { header: "更新时间", cell: item => item.updateTime },
                                    { header: "更新人", cell: item => item.updatedBy },
                                    { header: "批准人", cell: item => item.approvedBy }
                                ]}
                                items={[selectedTypeImage]}
                            />
                        </Container>
                    )}
                    <FormField label="输入多个序列号（每行一个）">
                        <Textarea
                            value={bulkSerialNumbers}
                            onChange={({ detail }) => setBulkSerialNumbers(detail.value)}
                            rows={5}
                        />
                    </FormField>
                    {validationProgress > 0 && validationProgress < 100 && (
                        <ProgressBar
                            value={validationProgress}
                            label="验证进度"
                            description={`${Math.round(validationProgress)}%`}
                        />
                    )}

                    {bulkResults.map((result, index) => (
                        <Container key={index} header={<Header variant="h3">{result.serialNumber}</Header>}>
                            {result.error ? (
                                <Alert type="error" header={result.error} />
                            ) : (
                                <>
                                    {/* <Table
                                        columnDefinitions={[
                                            { header: "属性", cell: (item) => item.key },
                                            { header: "值", cell: (item) => renderValue(item.value) }
                                        ]}
                                        items={Object.entries(result.info).map(([key, value]) => ({ key, value }))}
                                    /> */}
                                    {result.image && (
                                        <>
                                            {/* <Header>已批准的备件图片</Header>
                                            <img 
                                                src={result.image.imageUrl} 
                                                alt="Approved Spare Part" 
                                                style={{maxWidth: '100%', maxHeight: '200px'}} 
                                            /> */}
                                        </>
                                    )}
                                </>
                            )}
                            <FormField label="检查结果">
                                <Select
                                    selectedOption={{ label: result.checkResult === 'APPROVED' ? '允许' : '拒绝', value: result.checkResult }}
                                    onChange={({ detail }) => handleCheckResultChange(index, detail.selectedOption.value as 'APPROVED' | 'REJECTED')}
                                    options={[
                                        { label: '允许', value: 'APPROVED' },
                                        { label: '拒绝', value: 'REJECTED' }
                                    ]}
                                />
                            </FormField>
                        </Container>
                    ))}
                </SpaceBetween>
            </Modal>

            <Modal
                onDismiss={() => setIsModalVisible(false)}
                visible={isModalVisible}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setIsModalVisible(false)}>取消</Button>
                            <Button variant="primary" onClick={() => handleModalSubmit('APPROVED')}>允许进入</Button>
                            <Button variant="primary" onClick={() => handleModalSubmit('REJECTED')}>拒绝进入</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="检查结果"
            >
                <SpaceBetween direction="vertical" size="l">
                    <Box>请选择检查结果：</Box>
                    {recordData.accessType === 'PERSON-ONLY' && (
                        <Container header={<Header variant="h3">携带设备</Header>}>
                            <SpaceBetween direction="vertical" size="xs">
                                <Box>
                                    YUBICO: {hasYubico ? '✓' : '✗'}
                                </Box>
                                <Box>
                                    对讲机: {hasIntercom ? '✓' : '✗'}
                                </Box>
                            </SpaceBetween>
                        </Container>
                    )}
                    {(recordData.accessType === 'WITH-SPARE' || recordData.accessType === 'WITH-TOOL') && (
                        <>
                            {!isPartUnderCustody && (
                                <Alert type="warning" header="备件不在监管之下" action={
                                    <Button
                                        href={`https://t.corp.amazon.com/${recordData.tickets}`}
                                        target="_blank"
                                        iconName="external"
                                    >
                                        Open
                                    </Button>
                                }>
                                    {recordData.tickets ? "已输入: ticket" : "请确保输入了有效的 Ticket"}
                                </Alert>
                            )}
                            {partInfo && (
                                <Container header={<Header variant="h2">备件信息</Header>}>
                                    <Table
                                        columnDefinitions={[
                                            { header: "属性", cell: (item) => item.key },
                                            { header: "值", cell: (item) => renderValue(item.value) }
                                        ]}
                                        items={Object.entries(partInfo).map(([key, value]) => ({ key, value }))}
                                    />
                                </Container>
                            )}
                            {sparePartImage && (
                                <Container header={<Header variant="h2">已批准的备件图片信息</Header>}>
                                    <Alert type="success" header="此备件图片已完全批准">
                                        该备件已获得至少两人的批准。
                                    </Alert>
                                    <img
                                        src={sparePartImage.imageUrl}
                                        alt="Approved Spare Part"
                                        style={{ maxWidth: '100%', maxHeight: '300px' }}
                                    />
                                    <Table
                                        columnDefinitions={[
                                            { header: "型号", cell: item => item.model },
                                            { header: "类型", cell: item => item.type },
                                            { header: "更新时间", cell: item => item.updateTime },
                                            { header: "更新人", cell: item => item.updatedBy },
                                            { header: "批准人", cell: item => item.approvedBy }
                                        ]}
                                        items={[sparePartImage]}
                                    />
                                </Container>
                            )}
                        </>
                    )}
                </SpaceBetween>
            </Modal>

            {/* 工具检查 Modal */}
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                size={"large"}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => {
                                setVisible(false);
                                handleModalSubmit('REJECTED');
                            }}>拒绝进入</Button>
                            <Button variant="primary" onClick={() => {
                                setVisible(false);
                                handleModalSubmit('APPROVED');
                            }}>允许进入</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="查看详情"
            >
                <Grid
                    gridDefinition={[
                        { colspan: { xl: 3, l: 3, s: 3, xxs: 3 } },
                        { colspan: { xl: 8, l: 8, s: 8, xxs: 8 } }
                    ]}
                >
                    <div>
                        <SpaceBetween direction="vertical" size="l">
                            <Box>
                                <FormField label="类别">
                                    <p>{isCatTool ? catToolData?.category : edeData.category}</p>
                                </FormField>
                            </Box>
                            <Box>
                                <FormField label="厂商">
                                    <p>{isCatTool ? catToolData?.maker : edeData.maker}</p>
                                </FormField>
                            </Box>
                            <Box>
                                <FormField label="型号">
                                    <p>{isCatTool ? catToolData?.modelNumber : edeData.model}</p>
                                </FormField>
                            </Box>
                            {isCatTool ? (
                                <>
                                    <Box>
                                        <FormField label="定义">
                                            <p>{catToolData?.definitions}</p>
                                        </FormField>
                                    </Box>
                                    <Box>
                                        <FormField label="缓解措施">
                                            <p>{catToolData?.mitigations}</p>
                                        </FormField>
                                    </Box>
                                    <Box>
                                        <FormField label="注意事项">
                                            <p>{catToolData?.notes}</p>
                                        </FormField>
                                    </Box>
                                    <Box>
                                        <FormField label="批准状态">
                                            <p>{catToolData?.approved}</p>
                                        </FormField>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box>
                                        <FormField label="管控措施">
                                            {mitigationStatus}
                                        </FormField>
                                    </Box>
                                    <Box>
                                        <FormField label="状态">
                                            {statusData}
                                        </FormField>
                                    </Box>
                                    <Box>
                                        <FormField label="注意">
                                            <Markdown>{edeData.notes}</Markdown>
                                        </FormField>
                                    </Box>
                                </>
                            )}
                        </SpaceBetween>
                    </div>
                    <div>
                        <SpaceBetween direction="horizontal" size="l">
                            <Box
                                margin={{ left: "l" }}
                                padding={{ left: "l" }}
                                float="right">
                                <img
                                    src={isCatTool ? catToolData?.imageUrl : srcUrl}
                                    width={"100%"}
                                />
                            </Box>
                        </SpaceBetween>
                    </div>
                </Grid>
            </Modal>

            {/* CICO Exception Modal */}
            <Modal
                onDismiss={() => setVisibleexce(false)}
                visible={isVisibleexce}
                size={"large"}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => {
                                setVisibleexce(false);
                                handleModalSubmit('REJECTED');
                            }}>拒绝进入</Button>
                            <Button variant="primary" onClick={() => {
                                setVisibleexce(false);
                                handleModalSubmit('APPROVED');
                            }}>允许进入</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="查看详情"
            >
                <Grid
                    gridDefinition={[
                        { colspan: { xl: 3, l: 3, s: 3, xxs: 3 } },
                        { colspan: { xl: 8, l: 8, s: 8, xxs: 8 } }
                    ]}
                >
                    <div>
                        <SpaceBetween direction="vertical" size="l">
                            <Box>
                                <FormField label="类别">
                                    <p>{edeData.category}</p>
                                </FormField>
                            </Box>
                            <Box>
                                <FormField label="厂商">
                                    <p>{edeData.maker}</p>
                                </FormField>
                            </Box>
                            <Box>
                                <FormField label="型号">
                                    {<p>{edeData.model}</p>}
                                </FormField>
                            </Box>
                            <Box>
                                <FormField label="注意">
                                    {noteData}
                                </FormField>
                            </Box>
                            <Box>
                                <FormField label="状态">
                                    {statusData}
                                </FormField>
                            </Box>
                            <Box>
                                <FormField label="Approve ID">
                                    {appData}
                                </FormField>
                            </Box>
                            <Box>
                                <FormField label="封签号">
                                    {ssealData}
                                </FormField>
                            </Box>
                        </SpaceBetween>
                    </div>
                    <div>
                        <SpaceBetween direction="horizontal" size="l">
                            <Box
                                margin={{ left: "l" }}
                                padding={{ left: "l" }}
                                float="right">
                                <img
                                    src={srcUrl}
                                    width={"100%"}
                                />
                            </Box>
                        </SpaceBetween>
                    </div>
                </Grid>
            </Modal>



            {localRecords.length > 0 && (
                <Container
                    header={
                        <Header
                            variant="h2"
                            counter={`(${localRecords.length})`}
                        >
                            本次扫描记录
                        </Header>
                    }
                >
                    <Table
                        columnDefinitions={columnDefinitions}
                        items={localRecords}
                        loading={false}
                        loadingText="Loading records"
                        sortingDisabled
                        variant="embedded"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>无记录</b>
                                <Box
                                    padding={{ bottom: "s" }}
                                    variant="p"
                                    color="inherit"
                                >
                                    暂无扫描记录
                                </Box>
                            </Box>
                        }
                    />
                </Container>
            )}
        </>
    );
};