import * as React from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import {ExpandableSection} from "@amzn/awsui-components-react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Thumbnail from "@amzn/meridian/thumbnail";
import { Link } from "react-router-dom";



export default () => {



    return (
        <div
            style={{
                position: 'sticky',
                top: 20,
                zIndex: 1002,
                margin:10

            }}>
            {/* <SpaceBetween size='m'>
                <SpaceBetween direction="horizontal"  size="l">
                    <Link
                        style={{ textDecoration:'none'}}
                        to ='/scan'>
                        <SpaceBetween direction="horizontal" size="m">
                            <Container
                                header={
                                    <Header
                                        variant="h2"
                                        description="Parts In"
                                    >
                                        备件进入验证
                                    </Header>
                                }
                            >
                                <img />
                                只有“在Mobility中可以找到”或者“紧急RMA尚未Check In”的备件才能进入机房。
                            </Container>
                        </SpaceBetween></Link>
                    

                </SpaceBetween>
                <SpaceBetween direction="horizontal"  size='m'>
                    <SpaceBetween size="m">
                        <Link
                            style={{ textDecoration:'none'}}
                            to ='/tools'>
                            <Container
                                header={
                                    <Header
                                        variant="h2"
                                        description="Tools"
                                    >
                                        工具验证
                                    </Header>
                                }
                            >
                                只有处于“Approved”或“在EDE List中可以找到”状态的备件才能继续。
                            </Container></Link>
                    </SpaceBetween>

                    <SpaceBetween size="m">
                        <Link
                            style={{ textDecoration:'none'}}
                            to ='/listrecords'>
                            <Container
                                header={
                                    <Header
                                        variant="h2"
                                        description="Records"
                                    >
                                        记录查询和导出
                                    </Header>
                                }
                            >
                                查询及导出扫描记录
                            </Container></Link>
                    </SpaceBetween>
                </SpaceBetween>
            </SpaceBetween> */}
        </div>
    );
}