/* tslint:disable */
/* eslint-disable */
/**
 * An amazing API for ReactQiXinModel
 * An example service, with CRUD operations
 *
 * The version of the OpenAPI document: 2023-04-01
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiResponseContent
 */
export interface ApiResponseContent {
    /**
     * 
     * @type {string}
     * @memberof ApiResponseContent
     */
    'string'?: string;
}
/**
 * 
 * @export
 * @interface CheckSNResponseContent
 */
export interface CheckSNResponseContent {
    /**
     * 
     * @type {string}
     * @memberof CheckSNResponseContent
     */
    'string'?: string;
}
/**
 * 
 * @export
 * @interface CreateEdeRequestContent
 */
export interface CreateEdeRequestContent {
    /**
     * 
     * @type {string}
     * @memberof CreateEdeRequestContent
     */
    'model': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEdeRequestContent
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEdeRequestContent
     */
    'maker': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEdeRequestContent
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEdeRequestContent
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEdeRequestContent
     */
    'updateat': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEdeRequestContent
     */
    'updateby': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEdeRequestContent
     */
    'approvedby'?: string;
}
/**
 * 
 * @export
 * @interface CreateEdeResponseContent
 */
export interface CreateEdeResponseContent {
    /**
     * 
     * @type {Ede}
     * @memberof CreateEdeResponseContent
     */
    'created_ede': Ede;
}
/**
 * Level1 Pic attributes
 * @export
 * @interface CreateLevel1PicRequestContent
 */
export interface CreateLevel1PicRequestContent {
    /**
     * 
     * @type {string}
     * @memberof CreateLevel1PicRequestContent
     */
    'model': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLevel1PicRequestContent
     */
    'updateat': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLevel1PicRequestContent
     */
    'updateby': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLevel1PicRequestContent
     */
    'approvedby'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLevel1PicRequestContent
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLevel1PicRequestContent
     */
    'picContent': string;
}
/**
 * Created Level1 Pic
 * @export
 * @interface CreateLevel1PicResponseContent
 */
export interface CreateLevel1PicResponseContent {
    /**
     * 
     * @type {Level1Pic}
     * @memberof CreateLevel1PicResponseContent
     */
    'created_level1Pic': Level1Pic;
}
/**
 * Pet attributes
 * @export
 * @interface CreatePetRequestContent
 */
export interface CreatePetRequestContent {
    /**
     * 
     * @type {string}
     * @memberof CreatePetRequestContent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePetRequestContent
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePetRequestContent
     */
    'tag'?: string;
}
/**
 * Created Pet
 * @export
 * @interface CreatePetResponseContent
 */
export interface CreatePetResponseContent {
    /**
     * 
     * @type {Pet}
     * @memberof CreatePetResponseContent
     */
    'created_pet': Pet;
}
/**
 * 
 * @export
 * @interface CreateRoomAccessRecordRequestContent
 */
export interface CreateRoomAccessRecordRequestContent {
    /**
     * 
     * @type {string}
     * @memberof CreateRoomAccessRecordRequestContent
     */
    'operator': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomAccessRecordRequestContent
     */
    'securityGuard': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomAccessRecordRequestContent
     */
    'accessType': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomAccessRecordRequestContent
     */
    'roomInfo': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomAccessRecordRequestContent
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomAccessRecordRequestContent
     */
    'tickets': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomAccessRecordRequestContent
     */
    'serialNumberOrModel'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomAccessRecordRequestContent
     */
    'checkTime': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomAccessRecordRequestContent
     */
    'checkResult': string;
}
/**
 * 
 * @export
 * @interface CreateRoomAccessRecordResponseContent
 */
export interface CreateRoomAccessRecordResponseContent {
    /**
     * 
     * @type {RoomAccessRecord}
     * @memberof CreateRoomAccessRecordResponseContent
     */
    'record': RoomAccessRecord;
}
/**
 * 
 * @export
 * @interface CreateScanRequestContent
 */
export interface CreateScanRequestContent {
    /**
     * 
     * @type {string}
     * @memberof CreateScanRequestContent
     */
    'bb_login': string;
    /**
     * 
     * @type {string}
     * @memberof CreateScanRequestContent
     */
    'guard_login': string;
    /**
     * 
     * @type {string}
     * @memberof CreateScanRequestContent
     */
    'scan_deta'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScanRequestContent
     */
    'room': string;
    /**
     * 
     * @type {string}
     * @memberof CreateScanRequestContent
     */
    'tickets'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScanRequestContent
     */
    'sn': string;
    /**
     * 
     * @type {string}
     * @memberof CreateScanRequestContent
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScanRequestContent
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface CreateScanResponseContent
 */
export interface CreateScanResponseContent {
    /**
     * 
     * @type {Scan}
     * @memberof CreateScanResponseContent
     */
    'created_scan': Scan;
}
/**
 * 
 * @export
 * @interface CreateToolRequestContent
 */
export interface CreateToolRequestContent {
    /**
     * 
     * @type {Tool}
     * @memberof CreateToolRequestContent
     */
    'tool': Tool;
}
/**
 * 
 * @export
 * @interface CreateToolResponseContent
 */
export interface CreateToolResponseContent {
    /**
     * 
     * @type {Tool}
     * @memberof CreateToolResponseContent
     */
    'tool': Tool;
}
/**
 * 
 * @export
 * @interface Ede
 */
export interface Ede {
    /**
     * 
     * @type {string}
     * @memberof Ede
     */
    'model': string;
    /**
     * 
     * @type {string}
     * @memberof Ede
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof Ede
     */
    'maker': string;
    /**
     * 
     * @type {string}
     * @memberof Ede
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof Ede
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Ede
     */
    'updateat': string;
    /**
     * 
     * @type {string}
     * @memberof Ede
     */
    'updateby': string;
    /**
     * 
     * @type {string}
     * @memberof Ede
     */
    'approvedby'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ede
     */
    'edeId': string;
}
/**
 * 
 * @export
 * @interface EdeGetResponseContent
 */
export interface EdeGetResponseContent {
    /**
     * 
     * @type {string}
     * @memberof EdeGetResponseContent
     */
    'string'?: string;
}
/**
 * 
 * @export
 * @interface GetEdeResponseContent
 */
export interface GetEdeResponseContent {
    /**
     * 
     * @type {Ede}
     * @memberof GetEdeResponseContent
     */
    'ede'?: Ede;
}
/**
 * The level1 pic identified by level1PicUuid
 * @export
 * @interface GetLevel1PicResponseContent
 */
export interface GetLevel1PicResponseContent {
    /**
     * 
     * @type {Level1Pic}
     * @memberof GetLevel1PicResponseContent
     */
    'level1Pic'?: Level1Pic;
}
/**
 * The pet identified by petId
 * @export
 * @interface GetPetResponseContent
 */
export interface GetPetResponseContent {
    /**
     * 
     * @type {Pet}
     * @memberof GetPetResponseContent
     */
    'pet'?: Pet;
}
/**
 * 
 * @export
 * @interface GetPicResponseContent
 */
export interface GetPicResponseContent {
    /**
     * 
     * @type {string}
     * @memberof GetPicResponseContent
     */
    'string'?: string;
}
/**
 * 
 * @export
 * @interface GetRoomAccessRecordResponseContent
 */
export interface GetRoomAccessRecordResponseContent {
    /**
     * 
     * @type {RoomAccessRecord}
     * @memberof GetRoomAccessRecordResponseContent
     */
    'record': RoomAccessRecord;
}
/**
 * 
 * @export
 * @interface GetScanResponseContent
 */
export interface GetScanResponseContent {
    /**
     * 
     * @type {Scan}
     * @memberof GetScanResponseContent
     */
    'scan'?: Scan;
}
/**
 * 
 * @export
 * @interface GetSparePartImageResponseContent
 */
export interface GetSparePartImageResponseContent {
    /**
     * 
     * @type {SparePartImage}
     * @memberof GetSparePartImageResponseContent
     */
    'sparePartImage': SparePartImage;
}
/**
 * 
 * @export
 * @interface GetTicketResponseContent
 */
export interface GetTicketResponseContent {
    /**
     * 
     * @type {string}
     * @memberof GetTicketResponseContent
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof GetTicketResponseContent
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof GetTicketResponseContent
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface GetToolResponseContent
 */
export interface GetToolResponseContent {
    /**
     * 
     * @type {Tool}
     * @memberof GetToolResponseContent
     */
    'tool': Tool;
}
/**
 * Invalid input
 * @export
 * @interface InvalidInputExceptionResponseContent
 */
export interface InvalidInputExceptionResponseContent {
    /**
     * 
     * @type {string}
     * @memberof InvalidInputExceptionResponseContent
     */
    'message'?: string;
}
/**
 * A Level1 Pic with identifier and attributes
 * @export
 * @interface Level1Pic
 */
export interface Level1Pic {
    /**
     * 
     * @type {string}
     * @memberof Level1Pic
     */
    'model': string;
    /**
     * 
     * @type {string}
     * @memberof Level1Pic
     */
    'updateat': string;
    /**
     * 
     * @type {string}
     * @memberof Level1Pic
     */
    'updateby': string;
    /**
     * 
     * @type {string}
     * @memberof Level1Pic
     */
    'approvedby'?: string;
    /**
     * 
     * @type {string}
     * @memberof Level1Pic
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Level1Pic
     */
    'picContent': string;
    /**
     * Level1 Pic identifier
     * @type {string}
     * @memberof Level1Pic
     */
    'level1PicUuid': string;
}
/**
 * 
 * @export
 * @interface ListCicosResponseContent
 */
export interface ListCicosResponseContent {
    /**
     * 
     * @type {Array<Ede>}
     * @memberof ListCicosResponseContent
     */
    'edes': Array<Ede>;
}
/**
 * 
 * @export
 * @interface ListEdesResponseContent
 */
export interface ListEdesResponseContent {
    /**
     * 
     * @type {Array<Ede>}
     * @memberof ListEdesResponseContent
     */
    'edes': Array<Ede>;
}
/**
 * Level1 Pic list
 * @export
 * @interface ListLevel1PicsResponseContent
 */
export interface ListLevel1PicsResponseContent {
    /**
     * Level1 Pic list
     * @type {Array<Level1Pic>}
     * @memberof ListLevel1PicsResponseContent
     */
    'level1Pics': Array<Level1Pic>;
}
/**
 * 
 * @export
 * @interface ListPendingReviewRecordsResponseContent
 */
export interface ListPendingReviewRecordsResponseContent {
    /**
     * 
     * @type {Array<RoomAccessRecord>}
     * @memberof ListPendingReviewRecordsResponseContent
     */
    'records': Array<RoomAccessRecord>;
}
/**
 * Pet list
 * @export
 * @interface ListPetsResponseContent
 */
export interface ListPetsResponseContent {
    /**
     * Pet list
     * @type {Array<Pet>}
     * @memberof ListPetsResponseContent
     */
    'pets': Array<Pet>;
}
/**
 * 
 * @export
 * @interface ListRoomAccessRecordsResponseContent
 */
export interface ListRoomAccessRecordsResponseContent {
    /**
     * 
     * @type {Array<RoomAccessRecord>}
     * @memberof ListRoomAccessRecordsResponseContent
     */
    'records': Array<RoomAccessRecord>;
}
/**
 * 
 * @export
 * @interface ListScansResponseContent
 */
export interface ListScansResponseContent {
    /**
     * 
     * @type {Array<Scan>}
     * @memberof ListScansResponseContent
     */
    'scans': Array<Scan>;
}
/**
 * 
 * @export
 * @interface ListSparePartImagesResponseContent
 */
export interface ListSparePartImagesResponseContent {
    /**
     * 
     * @type {Array<SparePartImage>}
     * @memberof ListSparePartImagesResponseContent
     */
    'sparePartImages': Array<SparePartImage>;
}
/**
 * 
 * @export
 * @interface ListToolsResponseContent
 */
export interface ListToolsResponseContent {
    /**
     * 
     * @type {Array<Tool>}
     * @memberof ListToolsResponseContent
     */
    'tools': Array<Tool>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof ListToolsResponseContent
     */
    'paginationInfo'?: PaginationInfo;
}
/**
 * Resource does not exist
 * @export
 * @interface NoSuchResourceResponseContent
 */
export interface NoSuchResourceResponseContent {
    /**
     * 
     * @type {string}
     * @memberof NoSuchResourceResponseContent
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface PaginationInfo
 */
export interface PaginationInfo {
    /**
     * 
     * @type {string}
     * @memberof PaginationInfo
     */
    'lastEvaluatedKey': string;
}
/**
 * A Pet with identifier and attributes
 * @export
 * @interface Pet
 */
export interface Pet {
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'tag'?: string;
    /**
     * Pet identifier
     * @type {string}
     * @memberof Pet
     */
    'petId': string;
}
/**
 * 
 * @export
 * @interface RoomAccessRecord
 */
export interface RoomAccessRecord {
    /**
     * 
     * @type {string}
     * @memberof RoomAccessRecord
     */
    'recordId': string;
    /**
     * 
     * @type {string}
     * @memberof RoomAccessRecord
     */
    'operator': string;
    /**
     * 
     * @type {string}
     * @memberof RoomAccessRecord
     */
    'securityGuard': string;
    /**
     * 
     * @type {string}
     * @memberof RoomAccessRecord
     */
    'accessType': string;
    /**
     * 
     * @type {string}
     * @memberof RoomAccessRecord
     */
    'roomInfo': string;
    /**
     * 
     * @type {string}
     * @memberof RoomAccessRecord
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof RoomAccessRecord
     */
    'tickets': string;
    /**
     * 
     * @type {string}
     * @memberof RoomAccessRecord
     */
    'serialNumberOrModel'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomAccessRecord
     */
    'checkTime': string;
    /**
     * 
     * @type {string}
     * @memberof RoomAccessRecord
     */
    'checkResult': string;
}
/**
 * 
 * @export
 * @interface Scan
 */
export interface Scan {
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'bb_login': string;
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'guard_login': string;
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'scan_deta'?: string;
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'room': string;
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'tickets'?: string;
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'sn': string;
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'scanId': string;
}
/**
 * 
 * @export
 * @interface SparePartImage
 */
export interface SparePartImage {
    /**
     * 
     * @type {string}
     * @memberof SparePartImage
     */
    'model': string;
    /**
     * 
     * @type {string}
     * @memberof SparePartImage
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof SparePartImage
     */
    'updateTime': string;
    /**
     * 
     * @type {string}
     * @memberof SparePartImage
     */
    'updatedBy': string;
    /**
     * 
     * @type {string}
     * @memberof SparePartImage
     */
    'approvedBy': string;
    /**
     * 
     * @type {string}
     * @memberof SparePartImage
     */
    'imageUrl': string;
}
/**
 * 
 * @export
 * @interface SparePartImageCreationInput
 */
export interface SparePartImageCreationInput {
    /**
     * 
     * @type {string}
     * @memberof SparePartImageCreationInput
     */
    'model': string;
    /**
     * 
     * @type {string}
     * @memberof SparePartImageCreationInput
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof SparePartImageCreationInput
     */
    'updateTime': string;
    /**
     * 
     * @type {string}
     * @memberof SparePartImageCreationInput
     */
    'updatedBy': string;
    /**
     * 
     * @type {string}
     * @memberof SparePartImageCreationInput
     */
    'approvedBy': string;
    /**
     * 
     * @type {string}
     * @memberof SparePartImageCreationInput
     */
    'fileContent': string;
    /**
     * 
     * @type {string}
     * @memberof SparePartImageCreationInput
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface Tool
 */
export interface Tool {
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'maker': string;
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'modelNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'definitions': string;
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'mitigations': string;
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'createAt': string;
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'createBy': string;
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'updateAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'updateBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'approved': string;
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'approvedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'detail'?: string;
}
/**
 * 
 * @export
 * @interface UpdateEdeRequestContent
 */
export interface UpdateEdeRequestContent {
    /**
     * 
     * @type {string}
     * @memberof UpdateEdeRequestContent
     */
    'model': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEdeRequestContent
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEdeRequestContent
     */
    'maker': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEdeRequestContent
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEdeRequestContent
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEdeRequestContent
     */
    'updateat': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEdeRequestContent
     */
    'updateby': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEdeRequestContent
     */
    'approvedby'?: string;
}
/**
 * 
 * @export
 * @interface UpdateEdeResponseContent
 */
export interface UpdateEdeResponseContent {
    /**
     * 
     * @type {Ede}
     * @memberof UpdateEdeResponseContent
     */
    'updated_ede': Ede;
}
/**
 * Update Level1 Pic attributes and identifier
 * @export
 * @interface UpdateLevel1PicRequestContent
 */
export interface UpdateLevel1PicRequestContent {
    /**
     * 
     * @type {string}
     * @memberof UpdateLevel1PicRequestContent
     */
    'model': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLevel1PicRequestContent
     */
    'updateat': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLevel1PicRequestContent
     */
    'updateby': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLevel1PicRequestContent
     */
    'approvedby'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLevel1PicRequestContent
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLevel1PicRequestContent
     */
    'picContent': string;
}
/**
 * Updated Level1 Pic
 * @export
 * @interface UpdateLevel1PicResponseContent
 */
export interface UpdateLevel1PicResponseContent {
    /**
     * 
     * @type {Level1Pic}
     * @memberof UpdateLevel1PicResponseContent
     */
    'updated_level1Pic': Level1Pic;
}
/**
 * Update Pet attributes and identifier
 * @export
 * @interface UpdatePetRequestContent
 */
export interface UpdatePetRequestContent {
    /**
     * 
     * @type {string}
     * @memberof UpdatePetRequestContent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePetRequestContent
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePetRequestContent
     */
    'tag'?: string;
}
/**
 * Updated Pet
 * @export
 * @interface UpdatePetResponseContent
 */
export interface UpdatePetResponseContent {
    /**
     * 
     * @type {Pet}
     * @memberof UpdatePetResponseContent
     */
    'updated_pet': Pet;
}
/**
 * 
 * @export
 * @interface UpdateRoomAccessRecordRequestContent
 */
export interface UpdateRoomAccessRecordRequestContent {
    /**
     * 
     * @type {string}
     * @memberof UpdateRoomAccessRecordRequestContent
     */
    'checkResult': string;
}
/**
 * 
 * @export
 * @interface UpdateRoomAccessRecordResponseContent
 */
export interface UpdateRoomAccessRecordResponseContent {
    /**
     * 
     * @type {RoomAccessRecord}
     * @memberof UpdateRoomAccessRecordResponseContent
     */
    'record': RoomAccessRecord;
}
/**
 * 
 * @export
 * @interface UpdateScanRequestContent
 */
export interface UpdateScanRequestContent {
    /**
     * 
     * @type {string}
     * @memberof UpdateScanRequestContent
     */
    'bb_login': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScanRequestContent
     */
    'guard_login': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScanRequestContent
     */
    'scan_deta'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScanRequestContent
     */
    'room': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScanRequestContent
     */
    'tickets'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScanRequestContent
     */
    'sn': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScanRequestContent
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScanRequestContent
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface UpdateScanResponseContent
 */
export interface UpdateScanResponseContent {
    /**
     * 
     * @type {Scan}
     * @memberof UpdateScanResponseContent
     */
    'updated_scan': Scan;
}
/**
 * 
 * @export
 * @interface UpdateSparePartImageRequestContent
 */
export interface UpdateSparePartImageRequestContent {
    /**
     * 
     * @type {string}
     * @memberof UpdateSparePartImageRequestContent
     */
    'updateTime': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSparePartImageRequestContent
     */
    'updatedBy': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSparePartImageRequestContent
     */
    'approvedBy': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSparePartImageRequestContent
     */
    'fileContent': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSparePartImageRequestContent
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface UpdateSparePartImageResponseContent
 */
export interface UpdateSparePartImageResponseContent {
    /**
     * 
     * @type {SparePartImage}
     * @memberof UpdateSparePartImageResponseContent
     */
    'updatedImage': SparePartImage;
}
/**
 * 
 * @export
 * @interface UpdateToolRequestContent
 */
export interface UpdateToolRequestContent {
    /**
     * 
     * @type {Tool}
     * @memberof UpdateToolRequestContent
     */
    'tool': Tool;
}
/**
 * 
 * @export
 * @interface UpdateToolResponseContent
 */
export interface UpdateToolResponseContent {
    /**
     * 
     * @type {Tool}
     * @memberof UpdateToolResponseContent
     */
    'tool': Tool;
}
/**
 * 
 * @export
 * @interface UploadFileRequestContent
 */
export interface UploadFileRequestContent {
    /**
     * 
     * @type {string}
     * @memberof UploadFileRequestContent
     */
    'fileContent': string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileRequestContent
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface UploadFileResponseContent
 */
export interface UploadFileResponseContent {
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponseContent
     */
    'string'?: string;
}
/**
 * 
 * @export
 * @interface UploadSparePartImageRequestContent
 */
export interface UploadSparePartImageRequestContent {
    /**
     * 
     * @type {SparePartImageCreationInput}
     * @memberof UploadSparePartImageRequestContent
     */
    'sparePartImage': SparePartImageCreationInput;
}
/**
 * 
 * @export
 * @interface UploadSparePartImageResponseContent
 */
export interface UploadSparePartImageResponseContent {
    /**
     * 
     * @type {SparePartImage}
     * @memberof UploadSparePartImageResponseContent
     */
    'uploadedImage': SparePartImage;
}
/**
 * The identity of the caller
 * @export
 * @interface WhoAmIResponseContent
 */
export interface WhoAmIResponseContent {
    /**
     * 
     * @type {string}
     * @memberof WhoAmIResponseContent
     */
    'identity': string;
}

/**
 * CORSApi - axios parameter creator
 * @export
 */
export const CORSApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsApi: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsCicos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cicos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} edemodel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsEdegetEdemodel: async (edemodel: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'edemodel' is not null or undefined
            assertParamExists('corsEdegetEdemodel', 'edemodel', edemodel)
            const localVarPath = `/edeget/{edemodel}`
                .replace(`{${"edemodel"}}`, encodeURIComponent(String(edemodel)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsEdes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/edes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} edeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsEdesEdeid: async (edeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'edeId' is not null or undefined
            assertParamExists('corsEdesEdeid', 'edeId', edeId)
            const localVarPath = `/edes/{edeId}`
                .replace(`{${"edeId"}}`, encodeURIComponent(String(edeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} picname 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsGetedepicPicname: async (picname: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'picname' is not null or undefined
            assertParamExists('corsGetedepicPicname', 'picname', picname)
            const localVarPath = `/getedepic/{picname}`
                .replace(`{${"picname"}}`, encodeURIComponent(String(picname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsLevel1pics: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/level1pics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} level1PicUuid Level1 Pic identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsLevel1picsLevel1picuuid: async (level1PicUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'level1PicUuid' is not null or undefined
            assertParamExists('corsLevel1picsLevel1picuuid', 'level1PicUuid', level1PicUuid)
            const localVarPath = `/level1pics/{level1PicUuid}`
                .replace(`{${"level1PicUuid"}}`, encodeURIComponent(String(level1PicUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPetsPetid: async (petId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('corsPetsPetid', 'petId', petId)
            const localVarPath = `/pets/{petId}`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsRoomAccessRecordPendingReview: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/room-access-record/pending-review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsRoomAccessRecords: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/room-access-records`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} recordId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsRoomAccessRecordsRecordid: async (recordId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordId' is not null or undefined
            assertParamExists('corsRoomAccessRecordsRecordid', 'recordId', recordId)
            const localVarPath = `/room-access-records/{recordId}`
                .replace(`{${"recordId"}}`, encodeURIComponent(String(recordId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsScans: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} scanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsScansScanid: async (scanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('corsScansScanid', 'scanId', scanId)
            const localVarPath = `/scans/{scanId}`
                .replace(`{${"scanId"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} partsSn 
         * @param {string} userLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSnCheckPartsSnUserLogin: async (partsSn: string, userLogin: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partsSn' is not null or undefined
            assertParamExists('corsSnCheckPartsSnUserLogin', 'partsSn', partsSn)
            // verify required parameter 'userLogin' is not null or undefined
            assertParamExists('corsSnCheckPartsSnUserLogin', 'userLogin', userLogin)
            const localVarPath = `/sn_check/{parts_sn}/{user_login}`
                .replace(`{${"parts_sn"}}`, encodeURIComponent(String(partsSn)))
                .replace(`{${"user_login"}}`, encodeURIComponent(String(userLogin)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSparePartImages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/spare-part-images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} model 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSparePartImagesModelType: async (model: string, type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'model' is not null or undefined
            assertParamExists('corsSparePartImagesModelType', 'model', model)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('corsSparePartImagesModelType', 'type', type)
            const localVarPath = `/spare-part-images/{model}/{type}`
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsTicketyTicketid: async (ticketId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('corsTicketyTicketid', 'ticketId', ticketId)
            const localVarPath = `/tickety/{ticketId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsTools: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsToolsUuid: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('corsToolsUuid', 'uuid', uuid)
            const localVarPath = `/tools/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsUpdateFile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/update_file/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsWhoami: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/whoami`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CORSApi - functional programming interface
 * @export
 */
export const CORSApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CORSApiAxiosParamCreator(configuration)
    return {
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsApi(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsApi(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsCicos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsCicos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} edemodel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsEdegetEdemodel(edemodel: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsEdegetEdemodel(edemodel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsEdes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsEdes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} edeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsEdesEdeid(edeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsEdesEdeid(edeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} picname 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsGetedepicPicname(picname: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsGetedepicPicname(picname, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsLevel1pics(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsLevel1pics(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} level1PicUuid Level1 Pic identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsLevel1picsLevel1picuuid(level1PicUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsLevel1picsLevel1picuuid(level1PicUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsPets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsPets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsPetsPetid(petId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsPetsPetid(petId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsRoomAccessRecordPendingReview(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsRoomAccessRecordPendingReview(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsRoomAccessRecords(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsRoomAccessRecords(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} recordId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsRoomAccessRecordsRecordid(recordId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsRoomAccessRecordsRecordid(recordId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsScans(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsScans(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} scanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsScansScanid(scanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsScansScanid(scanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} partsSn 
         * @param {string} userLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsSnCheckPartsSnUserLogin(partsSn: string, userLogin: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsSnCheckPartsSnUserLogin(partsSn, userLogin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsSparePartImages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsSparePartImages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} model 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsSparePartImagesModelType(model: string, type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsSparePartImagesModelType(model, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsTicketyTicketid(ticketId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsTicketyTicketid(ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsTools(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsTools(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsToolsUuid(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsToolsUuid(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsUpdateFile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsUpdateFile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsWhoami(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsWhoami(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CORSApi - factory interface
 * @export
 */
export const CORSApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CORSApiFp(configuration)
    return {
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsApi(options?: any): AxiosPromise<void> {
            return localVarFp.corsApi(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsCicos(options?: any): AxiosPromise<void> {
            return localVarFp.corsCicos(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} edemodel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsEdegetEdemodel(edemodel: string, options?: any): AxiosPromise<void> {
            return localVarFp.corsEdegetEdemodel(edemodel, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsEdes(options?: any): AxiosPromise<void> {
            return localVarFp.corsEdes(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} edeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsEdesEdeid(edeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.corsEdesEdeid(edeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} picname 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsGetedepicPicname(picname: string, options?: any): AxiosPromise<void> {
            return localVarFp.corsGetedepicPicname(picname, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsLevel1pics(options?: any): AxiosPromise<void> {
            return localVarFp.corsLevel1pics(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} level1PicUuid Level1 Pic identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsLevel1picsLevel1picuuid(level1PicUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.corsLevel1picsLevel1picuuid(level1PicUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPets(options?: any): AxiosPromise<void> {
            return localVarFp.corsPets(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPetsPetid(petId: string, options?: any): AxiosPromise<void> {
            return localVarFp.corsPetsPetid(petId, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsRoomAccessRecordPendingReview(options?: any): AxiosPromise<void> {
            return localVarFp.corsRoomAccessRecordPendingReview(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsRoomAccessRecords(options?: any): AxiosPromise<void> {
            return localVarFp.corsRoomAccessRecords(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} recordId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsRoomAccessRecordsRecordid(recordId: string, options?: any): AxiosPromise<void> {
            return localVarFp.corsRoomAccessRecordsRecordid(recordId, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsScans(options?: any): AxiosPromise<void> {
            return localVarFp.corsScans(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} scanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsScansScanid(scanId: string, options?: any): AxiosPromise<void> {
            return localVarFp.corsScansScanid(scanId, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} partsSn 
         * @param {string} userLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSnCheckPartsSnUserLogin(partsSn: string, userLogin: string, options?: any): AxiosPromise<void> {
            return localVarFp.corsSnCheckPartsSnUserLogin(partsSn, userLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSparePartImages(options?: any): AxiosPromise<void> {
            return localVarFp.corsSparePartImages(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} model 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSparePartImagesModelType(model: string, type: string, options?: any): AxiosPromise<void> {
            return localVarFp.corsSparePartImagesModelType(model, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsTicketyTicketid(ticketId: string, options?: any): AxiosPromise<void> {
            return localVarFp.corsTicketyTicketid(ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsTools(options?: any): AxiosPromise<void> {
            return localVarFp.corsTools(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsToolsUuid(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.corsToolsUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsUpdateFile(options?: any): AxiosPromise<void> {
            return localVarFp.corsUpdateFile(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsWhoami(options?: any): AxiosPromise<void> {
            return localVarFp.corsWhoami(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CORSApi - object-oriented interface
 * @export
 * @class CORSApi
 * @extends {BaseAPI}
 */
export class CORSApi extends BaseAPI {
    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsApi(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsApi(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsCicos(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsCicos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {string} edemodel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsEdegetEdemodel(edemodel: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsEdegetEdemodel(edemodel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsEdes(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsEdes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {string} edeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsEdesEdeid(edeId: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsEdesEdeid(edeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {string} picname 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsGetedepicPicname(picname: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsGetedepicPicname(picname, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsLevel1pics(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsLevel1pics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {string} level1PicUuid Level1 Pic identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsLevel1picsLevel1picuuid(level1PicUuid: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsLevel1picsLevel1picuuid(level1PicUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsPets(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsPets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {string} petId Pet identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsPetsPetid(petId: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsPetsPetid(petId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsRoomAccessRecordPendingReview(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsRoomAccessRecordPendingReview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsRoomAccessRecords(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsRoomAccessRecords(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {string} recordId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsRoomAccessRecordsRecordid(recordId: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsRoomAccessRecordsRecordid(recordId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsScans(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsScans(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {string} scanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsScansScanid(scanId: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsScansScanid(scanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {string} partsSn 
     * @param {string} userLogin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsSnCheckPartsSnUserLogin(partsSn: string, userLogin: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsSnCheckPartsSnUserLogin(partsSn, userLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsSparePartImages(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsSparePartImages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {string} model 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsSparePartImagesModelType(model: string, type: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsSparePartImagesModelType(model, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {string} ticketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsTicketyTicketid(ticketId: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsTicketyTicketid(ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsTools(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsTools(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsToolsUuid(uuid: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsToolsUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsUpdateFile(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsUpdateFile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsWhoami(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsWhoami(options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        api: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partsSn 
         * @param {string} userLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSN: async (partsSn: string, userLogin: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partsSn' is not null or undefined
            assertParamExists('checkSN', 'partsSn', partsSn)
            // verify required parameter 'userLogin' is not null or undefined
            assertParamExists('checkSN', 'userLogin', userLogin)
            const localVarPath = `/sn_check/{parts_sn}/{user_login}`
                .replace(`{${"parts_sn"}}`, encodeURIComponent(String(partsSn)))
                .replace(`{${"user_login"}}`, encodeURIComponent(String(userLogin)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateEdeRequestContent} createEdeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEde: async (createEdeRequestContent: CreateEdeRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEdeRequestContent' is not null or undefined
            assertParamExists('createEde', 'createEdeRequestContent', createEdeRequestContent)
            const localVarPath = `/edes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEdeRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a Level1 Pic
         * @param {CreateLevel1PicRequestContent} createLevel1PicRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLevel1Pic: async (createLevel1PicRequestContent: CreateLevel1PicRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLevel1PicRequestContent' is not null or undefined
            assertParamExists('createLevel1Pic', 'createLevel1PicRequestContent', createLevel1PicRequestContent)
            const localVarPath = `/level1pics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLevel1PicRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a Pet
         * @param {CreatePetRequestContent} createPetRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPet: async (createPetRequestContent: CreatePetRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPetRequestContent' is not null or undefined
            assertParamExists('createPet', 'createPetRequestContent', createPetRequestContent)
            const localVarPath = `/pets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPetRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateRoomAccessRecordRequestContent} createRoomAccessRecordRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoomAccessRecord: async (createRoomAccessRecordRequestContent: CreateRoomAccessRecordRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRoomAccessRecordRequestContent' is not null or undefined
            assertParamExists('createRoomAccessRecord', 'createRoomAccessRecordRequestContent', createRoomAccessRecordRequestContent)
            const localVarPath = `/room-access-records`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoomAccessRecordRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateScanRequestContent} createScanRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScan: async (createScanRequestContent: CreateScanRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createScanRequestContent' is not null or undefined
            assertParamExists('createScan', 'createScanRequestContent', createScanRequestContent)
            const localVarPath = `/scans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createScanRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateToolRequestContent} createToolRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTool: async (createToolRequestContent: CreateToolRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createToolRequestContent' is not null or undefined
            assertParamExists('createTool', 'createToolRequestContent', createToolRequestContent)
            const localVarPath = `/tools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createToolRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} edeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEde: async (edeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'edeId' is not null or undefined
            assertParamExists('deleteEde', 'edeId', edeId)
            const localVarPath = `/edes/{edeId}`
                .replace(`{${"edeId"}}`, encodeURIComponent(String(edeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Level1 Pic by uuid
         * @param {string} level1PicUuid Level1 Pic identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLevel1Pic: async (level1PicUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'level1PicUuid' is not null or undefined
            assertParamExists('deleteLevel1Pic', 'level1PicUuid', level1PicUuid)
            const localVarPath = `/level1pics/{level1PicUuid}`
                .replace(`{${"level1PicUuid"}}`, encodeURIComponent(String(level1PicUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Pet by id
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePet: async (petId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('deletePet', 'petId', petId)
            const localVarPath = `/pets/{petId}`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} scanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScan: async (scanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('deleteScan', 'scanId', scanId)
            const localVarPath = `/scans/{scanId}`
                .replace(`{${"scanId"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} model 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSparePartImage: async (model: string, type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'model' is not null or undefined
            assertParamExists('deleteSparePartImage', 'model', model)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('deleteSparePartImage', 'type', type)
            const localVarPath = `/spare-part-images/{model}/{type}`
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTool: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('deleteTool', 'uuid', uuid)
            const localVarPath = `/tools/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} edemodel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edeGet: async (edemodel: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'edemodel' is not null or undefined
            assertParamExists('edeGet', 'edemodel', edemodel)
            const localVarPath = `/edeget/{edemodel}`
                .replace(`{${"edemodel"}}`, encodeURIComponent(String(edemodel)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} edeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEde: async (edeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'edeId' is not null or undefined
            assertParamExists('getEde', 'edeId', edeId)
            const localVarPath = `/edes/{edeId}`
                .replace(`{${"edeId"}}`, encodeURIComponent(String(edeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a Level1 Pic by uuid
         * @param {string} level1PicUuid Level1 Pic identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLevel1Pic: async (level1PicUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'level1PicUuid' is not null or undefined
            assertParamExists('getLevel1Pic', 'level1PicUuid', level1PicUuid)
            const localVarPath = `/level1pics/{level1PicUuid}`
                .replace(`{${"level1PicUuid"}}`, encodeURIComponent(String(level1PicUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a Pet by id
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPet: async (petId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('getPet', 'petId', petId)
            const localVarPath = `/pets/{petId}`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} picname 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPic: async (picname: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'picname' is not null or undefined
            assertParamExists('getPic', 'picname', picname)
            const localVarPath = `/getedepic/{picname}`
                .replace(`{${"picname"}}`, encodeURIComponent(String(picname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} recordId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomAccessRecord: async (recordId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordId' is not null or undefined
            assertParamExists('getRoomAccessRecord', 'recordId', recordId)
            const localVarPath = `/room-access-records/{recordId}`
                .replace(`{${"recordId"}}`, encodeURIComponent(String(recordId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} scanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScan: async (scanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('getScan', 'scanId', scanId)
            const localVarPath = `/scans/{scanId}`
                .replace(`{${"scanId"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} model 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSparePartImage: async (model: string, type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'model' is not null or undefined
            assertParamExists('getSparePartImage', 'model', model)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getSparePartImage', 'type', type)
            const localVarPath = `/spare-part-images/{model}/{type}`
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicket: async (ticketId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('getTicket', 'ticketId', ticketId)
            const localVarPath = `/tickety/{ticketId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTool: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getTool', 'uuid', uuid)
            const localVarPath = `/tools/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCicos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cicos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEdes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/edes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of Level1 Pics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLevel1Pics: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/level1pics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPendingReviewRecords: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/room-access-record/pending-review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of Pets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoomAccessRecords: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/room-access-records`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScans: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSparePartImages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/spare-part-images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [lastEvaluatedKey] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTools: async (lastEvaluatedKey?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lastEvaluatedKey !== undefined) {
                localVarQueryParameter['lastEvaluatedKey'] = lastEvaluatedKey;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a Ede
         * @param {string} edeId 
         * @param {UpdateEdeRequestContent} updateEdeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEde: async (edeId: string, updateEdeRequestContent: UpdateEdeRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'edeId' is not null or undefined
            assertParamExists('updateEde', 'edeId', edeId)
            // verify required parameter 'updateEdeRequestContent' is not null or undefined
            assertParamExists('updateEde', 'updateEdeRequestContent', updateEdeRequestContent)
            const localVarPath = `/edes/{edeId}`
                .replace(`{${"edeId"}}`, encodeURIComponent(String(edeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEdeRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a Level1 Pic
         * @param {string} level1PicUuid Level1 Pic identifier
         * @param {UpdateLevel1PicRequestContent} updateLevel1PicRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLevel1Pic: async (level1PicUuid: string, updateLevel1PicRequestContent: UpdateLevel1PicRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'level1PicUuid' is not null or undefined
            assertParamExists('updateLevel1Pic', 'level1PicUuid', level1PicUuid)
            // verify required parameter 'updateLevel1PicRequestContent' is not null or undefined
            assertParamExists('updateLevel1Pic', 'updateLevel1PicRequestContent', updateLevel1PicRequestContent)
            const localVarPath = `/level1pics/{level1PicUuid}`
                .replace(`{${"level1PicUuid"}}`, encodeURIComponent(String(level1PicUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLevel1PicRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a Pet
         * @param {string} petId Pet identifier
         * @param {UpdatePetRequestContent} updatePetRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePet: async (petId: string, updatePetRequestContent: UpdatePetRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('updatePet', 'petId', petId)
            // verify required parameter 'updatePetRequestContent' is not null or undefined
            assertParamExists('updatePet', 'updatePetRequestContent', updatePetRequestContent)
            const localVarPath = `/pets/{petId}`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePetRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} recordId 
         * @param {UpdateRoomAccessRecordRequestContent} updateRoomAccessRecordRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoomAccessRecord: async (recordId: string, updateRoomAccessRecordRequestContent: UpdateRoomAccessRecordRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordId' is not null or undefined
            assertParamExists('updateRoomAccessRecord', 'recordId', recordId)
            // verify required parameter 'updateRoomAccessRecordRequestContent' is not null or undefined
            assertParamExists('updateRoomAccessRecord', 'updateRoomAccessRecordRequestContent', updateRoomAccessRecordRequestContent)
            const localVarPath = `/room-access-records/{recordId}`
                .replace(`{${"recordId"}}`, encodeURIComponent(String(recordId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoomAccessRecordRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a Scan
         * @param {string} scanId 
         * @param {UpdateScanRequestContent} updateScanRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScan: async (scanId: string, updateScanRequestContent: UpdateScanRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('updateScan', 'scanId', scanId)
            // verify required parameter 'updateScanRequestContent' is not null or undefined
            assertParamExists('updateScan', 'updateScanRequestContent', updateScanRequestContent)
            const localVarPath = `/scans/{scanId}`
                .replace(`{${"scanId"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateScanRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} model 
         * @param {string} type 
         * @param {UpdateSparePartImageRequestContent} updateSparePartImageRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSparePartImage: async (model: string, type: string, updateSparePartImageRequestContent: UpdateSparePartImageRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'model' is not null or undefined
            assertParamExists('updateSparePartImage', 'model', model)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('updateSparePartImage', 'type', type)
            // verify required parameter 'updateSparePartImageRequestContent' is not null or undefined
            assertParamExists('updateSparePartImage', 'updateSparePartImageRequestContent', updateSparePartImageRequestContent)
            const localVarPath = `/spare-part-images/{model}/{type}`
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSparePartImageRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {UpdateToolRequestContent} updateToolRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTool: async (uuid: string, updateToolRequestContent: UpdateToolRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('updateTool', 'uuid', uuid)
            // verify required parameter 'updateToolRequestContent' is not null or undefined
            assertParamExists('updateTool', 'updateToolRequestContent', updateToolRequestContent)
            const localVarPath = `/tools/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateToolRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadFileRequestContent} uploadFileRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (uploadFileRequestContent: UploadFileRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadFileRequestContent' is not null or undefined
            assertParamExists('uploadFile', 'uploadFileRequestContent', uploadFileRequestContent)
            const localVarPath = `/update_file/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadFileRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadSparePartImageRequestContent} uploadSparePartImageRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSparePartImage: async (uploadSparePartImageRequestContent: UploadSparePartImageRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadSparePartImageRequestContent' is not null or undefined
            assertParamExists('uploadSparePartImage', 'uploadSparePartImageRequestContent', uploadSparePartImageRequestContent)
            const localVarPath = `/spare-part-images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadSparePartImageRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the Identity of the API caller
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoAmI: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/whoami`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async api(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.api(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} partsSn 
         * @param {string} userLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkSN(partsSn: string, userLogin: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckSNResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkSN(partsSn, userLogin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateEdeRequestContent} createEdeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEde(createEdeRequestContent: CreateEdeRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateEdeResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEde(createEdeRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a Level1 Pic
         * @param {CreateLevel1PicRequestContent} createLevel1PicRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLevel1Pic(createLevel1PicRequestContent: CreateLevel1PicRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateLevel1PicResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLevel1Pic(createLevel1PicRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a Pet
         * @param {CreatePetRequestContent} createPetRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPet(createPetRequestContent: CreatePetRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePetResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPet(createPetRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateRoomAccessRecordRequestContent} createRoomAccessRecordRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoomAccessRecord(createRoomAccessRecordRequestContent: CreateRoomAccessRecordRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateRoomAccessRecordResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoomAccessRecord(createRoomAccessRecordRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateScanRequestContent} createScanRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScan(createScanRequestContent: CreateScanRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateScanResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScan(createScanRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateToolRequestContent} createToolRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTool(createToolRequestContent: CreateToolRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateToolResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTool(createToolRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} edeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEde(edeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEde(edeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a Level1 Pic by uuid
         * @param {string} level1PicUuid Level1 Pic identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLevel1Pic(level1PicUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLevel1Pic(level1PicUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a Pet by id
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePet(petId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePet(petId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} scanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScan(scanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScan(scanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} model 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSparePartImage(model: string, type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSparePartImage(model, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTool(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTool(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} edemodel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async edeGet(edemodel: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EdeGetResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.edeGet(edemodel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} edeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEde(edeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEdeResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEde(edeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a Level1 Pic by uuid
         * @param {string} level1PicUuid Level1 Pic identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLevel1Pic(level1PicUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLevel1PicResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLevel1Pic(level1PicUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a Pet by id
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPet(petId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPetResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPet(petId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} picname 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPic(picname: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPicResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPic(picname, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} recordId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoomAccessRecord(recordId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomAccessRecordResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoomAccessRecord(recordId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} scanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScan(scanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScanResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScan(scanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} model 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSparePartImage(model: string, type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSparePartImageResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSparePartImage(model, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicket(ticketId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTicketResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicket(ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTool(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetToolResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTool(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCicos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCicosResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCicos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEdes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEdesResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEdes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of Level1 Pics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLevel1Pics(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLevel1PicsResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listLevel1Pics(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPendingReviewRecords(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPendingReviewRecordsResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPendingReviewRecords(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of Pets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPetsResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRoomAccessRecords(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRoomAccessRecordsResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRoomAccessRecords(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listScans(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListScansResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listScans(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSparePartImages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSparePartImagesResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSparePartImages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [lastEvaluatedKey] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTools(lastEvaluatedKey?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListToolsResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTools(lastEvaluatedKey, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a Ede
         * @param {string} edeId 
         * @param {UpdateEdeRequestContent} updateEdeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEde(edeId: string, updateEdeRequestContent: UpdateEdeRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateEdeResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEde(edeId, updateEdeRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a Level1 Pic
         * @param {string} level1PicUuid Level1 Pic identifier
         * @param {UpdateLevel1PicRequestContent} updateLevel1PicRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLevel1Pic(level1PicUuid: string, updateLevel1PicRequestContent: UpdateLevel1PicRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateLevel1PicResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLevel1Pic(level1PicUuid, updateLevel1PicRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a Pet
         * @param {string} petId Pet identifier
         * @param {UpdatePetRequestContent} updatePetRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePet(petId: string, updatePetRequestContent: UpdatePetRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePetResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePet(petId, updatePetRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} recordId 
         * @param {UpdateRoomAccessRecordRequestContent} updateRoomAccessRecordRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoomAccessRecord(recordId: string, updateRoomAccessRecordRequestContent: UpdateRoomAccessRecordRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateRoomAccessRecordResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoomAccessRecord(recordId, updateRoomAccessRecordRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a Scan
         * @param {string} scanId 
         * @param {UpdateScanRequestContent} updateScanRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateScan(scanId: string, updateScanRequestContent: UpdateScanRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateScanResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateScan(scanId, updateScanRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} model 
         * @param {string} type 
         * @param {UpdateSparePartImageRequestContent} updateSparePartImageRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSparePartImage(model: string, type: string, updateSparePartImageRequestContent: UpdateSparePartImageRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSparePartImageResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSparePartImage(model, type, updateSparePartImageRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {UpdateToolRequestContent} updateToolRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTool(uuid: string, updateToolRequestContent: UpdateToolRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateToolResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTool(uuid, updateToolRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UploadFileRequestContent} uploadFileRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(uploadFileRequestContent: UploadFileRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(uploadFileRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UploadSparePartImageRequestContent} uploadSparePartImageRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadSparePartImage(uploadSparePartImageRequestContent: UploadSparePartImageRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadSparePartImageResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadSparePartImage(uploadSparePartImageRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the Identity of the API caller
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async whoAmI(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WhoAmIResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.whoAmI(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        api(options?: any): AxiosPromise<ApiResponseContent> {
            return localVarFp.api(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} partsSn 
         * @param {string} userLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSN(partsSn: string, userLogin: string, options?: any): AxiosPromise<CheckSNResponseContent> {
            return localVarFp.checkSN(partsSn, userLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateEdeRequestContent} createEdeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEde(createEdeRequestContent: CreateEdeRequestContent, options?: any): AxiosPromise<CreateEdeResponseContent> {
            return localVarFp.createEde(createEdeRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a Level1 Pic
         * @param {CreateLevel1PicRequestContent} createLevel1PicRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLevel1Pic(createLevel1PicRequestContent: CreateLevel1PicRequestContent, options?: any): AxiosPromise<CreateLevel1PicResponseContent> {
            return localVarFp.createLevel1Pic(createLevel1PicRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a Pet
         * @param {CreatePetRequestContent} createPetRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPet(createPetRequestContent: CreatePetRequestContent, options?: any): AxiosPromise<CreatePetResponseContent> {
            return localVarFp.createPet(createPetRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateRoomAccessRecordRequestContent} createRoomAccessRecordRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoomAccessRecord(createRoomAccessRecordRequestContent: CreateRoomAccessRecordRequestContent, options?: any): AxiosPromise<CreateRoomAccessRecordResponseContent> {
            return localVarFp.createRoomAccessRecord(createRoomAccessRecordRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateScanRequestContent} createScanRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScan(createScanRequestContent: CreateScanRequestContent, options?: any): AxiosPromise<CreateScanResponseContent> {
            return localVarFp.createScan(createScanRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateToolRequestContent} createToolRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTool(createToolRequestContent: CreateToolRequestContent, options?: any): AxiosPromise<CreateToolResponseContent> {
            return localVarFp.createTool(createToolRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} edeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEde(edeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEde(edeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Level1 Pic by uuid
         * @param {string} level1PicUuid Level1 Pic identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLevel1Pic(level1PicUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLevel1Pic(level1PicUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Pet by id
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePet(petId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePet(petId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} scanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScan(scanId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteScan(scanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} model 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSparePartImage(model: string, type: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSparePartImage(model, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTool(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTool(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} edemodel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edeGet(edemodel: string, options?: any): AxiosPromise<EdeGetResponseContent> {
            return localVarFp.edeGet(edemodel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} edeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEde(edeId: string, options?: any): AxiosPromise<GetEdeResponseContent> {
            return localVarFp.getEde(edeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a Level1 Pic by uuid
         * @param {string} level1PicUuid Level1 Pic identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLevel1Pic(level1PicUuid: string, options?: any): AxiosPromise<GetLevel1PicResponseContent> {
            return localVarFp.getLevel1Pic(level1PicUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a Pet by id
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPet(petId: string, options?: any): AxiosPromise<GetPetResponseContent> {
            return localVarFp.getPet(petId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} picname 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPic(picname: string, options?: any): AxiosPromise<GetPicResponseContent> {
            return localVarFp.getPic(picname, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} recordId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomAccessRecord(recordId: string, options?: any): AxiosPromise<GetRoomAccessRecordResponseContent> {
            return localVarFp.getRoomAccessRecord(recordId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} scanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScan(scanId: string, options?: any): AxiosPromise<GetScanResponseContent> {
            return localVarFp.getScan(scanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} model 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSparePartImage(model: string, type: string, options?: any): AxiosPromise<GetSparePartImageResponseContent> {
            return localVarFp.getSparePartImage(model, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicket(ticketId: string, options?: any): AxiosPromise<GetTicketResponseContent> {
            return localVarFp.getTicket(ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTool(uuid: string, options?: any): AxiosPromise<GetToolResponseContent> {
            return localVarFp.getTool(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCicos(options?: any): AxiosPromise<ListCicosResponseContent> {
            return localVarFp.listCicos(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEdes(options?: any): AxiosPromise<ListEdesResponseContent> {
            return localVarFp.listEdes(options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of Level1 Pics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLevel1Pics(options?: any): AxiosPromise<ListLevel1PicsResponseContent> {
            return localVarFp.listLevel1Pics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPendingReviewRecords(options?: any): AxiosPromise<ListPendingReviewRecordsResponseContent> {
            return localVarFp.listPendingReviewRecords(options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of Pets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPets(options?: any): AxiosPromise<ListPetsResponseContent> {
            return localVarFp.listPets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoomAccessRecords(options?: any): AxiosPromise<ListRoomAccessRecordsResponseContent> {
            return localVarFp.listRoomAccessRecords(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScans(options?: any): AxiosPromise<ListScansResponseContent> {
            return localVarFp.listScans(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSparePartImages(options?: any): AxiosPromise<ListSparePartImagesResponseContent> {
            return localVarFp.listSparePartImages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [lastEvaluatedKey] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTools(lastEvaluatedKey?: string, limit?: number, options?: any): AxiosPromise<ListToolsResponseContent> {
            return localVarFp.listTools(lastEvaluatedKey, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a Ede
         * @param {string} edeId 
         * @param {UpdateEdeRequestContent} updateEdeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEde(edeId: string, updateEdeRequestContent: UpdateEdeRequestContent, options?: any): AxiosPromise<UpdateEdeResponseContent> {
            return localVarFp.updateEde(edeId, updateEdeRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a Level1 Pic
         * @param {string} level1PicUuid Level1 Pic identifier
         * @param {UpdateLevel1PicRequestContent} updateLevel1PicRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLevel1Pic(level1PicUuid: string, updateLevel1PicRequestContent: UpdateLevel1PicRequestContent, options?: any): AxiosPromise<UpdateLevel1PicResponseContent> {
            return localVarFp.updateLevel1Pic(level1PicUuid, updateLevel1PicRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a Pet
         * @param {string} petId Pet identifier
         * @param {UpdatePetRequestContent} updatePetRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePet(petId: string, updatePetRequestContent: UpdatePetRequestContent, options?: any): AxiosPromise<UpdatePetResponseContent> {
            return localVarFp.updatePet(petId, updatePetRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} recordId 
         * @param {UpdateRoomAccessRecordRequestContent} updateRoomAccessRecordRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoomAccessRecord(recordId: string, updateRoomAccessRecordRequestContent: UpdateRoomAccessRecordRequestContent, options?: any): AxiosPromise<UpdateRoomAccessRecordResponseContent> {
            return localVarFp.updateRoomAccessRecord(recordId, updateRoomAccessRecordRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a Scan
         * @param {string} scanId 
         * @param {UpdateScanRequestContent} updateScanRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScan(scanId: string, updateScanRequestContent: UpdateScanRequestContent, options?: any): AxiosPromise<UpdateScanResponseContent> {
            return localVarFp.updateScan(scanId, updateScanRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} model 
         * @param {string} type 
         * @param {UpdateSparePartImageRequestContent} updateSparePartImageRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSparePartImage(model: string, type: string, updateSparePartImageRequestContent: UpdateSparePartImageRequestContent, options?: any): AxiosPromise<UpdateSparePartImageResponseContent> {
            return localVarFp.updateSparePartImage(model, type, updateSparePartImageRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {UpdateToolRequestContent} updateToolRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTool(uuid: string, updateToolRequestContent: UpdateToolRequestContent, options?: any): AxiosPromise<UpdateToolResponseContent> {
            return localVarFp.updateTool(uuid, updateToolRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadFileRequestContent} uploadFileRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(uploadFileRequestContent: UploadFileRequestContent, options?: any): AxiosPromise<UploadFileResponseContent> {
            return localVarFp.uploadFile(uploadFileRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadSparePartImageRequestContent} uploadSparePartImageRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSparePartImage(uploadSparePartImageRequestContent: UploadSparePartImageRequestContent, options?: any): AxiosPromise<UploadSparePartImageResponseContent> {
            return localVarFp.uploadSparePartImage(uploadSparePartImageRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the Identity of the API caller
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoAmI(options?: any): AxiosPromise<WhoAmIResponseContent> {
            return localVarFp.whoAmI(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public api(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).api(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} partsSn 
     * @param {string} userLogin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public checkSN(partsSn: string, userLogin: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).checkSN(partsSn, userLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateEdeRequestContent} createEdeRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createEde(createEdeRequestContent: CreateEdeRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createEde(createEdeRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a Level1 Pic
     * @param {CreateLevel1PicRequestContent} createLevel1PicRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createLevel1Pic(createLevel1PicRequestContent: CreateLevel1PicRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createLevel1Pic(createLevel1PicRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a Pet
     * @param {CreatePetRequestContent} createPetRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createPet(createPetRequestContent: CreatePetRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createPet(createPetRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateRoomAccessRecordRequestContent} createRoomAccessRecordRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createRoomAccessRecord(createRoomAccessRecordRequestContent: CreateRoomAccessRecordRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createRoomAccessRecord(createRoomAccessRecordRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateScanRequestContent} createScanRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createScan(createScanRequestContent: CreateScanRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createScan(createScanRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateToolRequestContent} createToolRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createTool(createToolRequestContent: CreateToolRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createTool(createToolRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} edeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteEde(edeId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteEde(edeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Level1 Pic by uuid
     * @param {string} level1PicUuid Level1 Pic identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteLevel1Pic(level1PicUuid: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteLevel1Pic(level1PicUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Pet by id
     * @param {string} petId Pet identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deletePet(petId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deletePet(petId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} scanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteScan(scanId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteScan(scanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} model 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteSparePartImage(model: string, type: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteSparePartImage(model, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteTool(uuid: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteTool(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} edemodel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public edeGet(edemodel: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).edeGet(edemodel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} edeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEde(edeId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getEde(edeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a Level1 Pic by uuid
     * @param {string} level1PicUuid Level1 Pic identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLevel1Pic(level1PicUuid: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getLevel1Pic(level1PicUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a Pet by id
     * @param {string} petId Pet identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPet(petId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPet(petId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} picname 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPic(picname: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPic(picname, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} recordId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRoomAccessRecord(recordId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getRoomAccessRecord(recordId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} scanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getScan(scanId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getScan(scanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} model 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSparePartImage(model: string, type: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSparePartImage(model, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ticketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTicket(ticketId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTicket(ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTool(uuid: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTool(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCicos(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listCicos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listEdes(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listEdes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of Level1 Pics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listLevel1Pics(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listLevel1Pics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPendingReviewRecords(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPendingReviewRecords(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of Pets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPets(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listRoomAccessRecords(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listRoomAccessRecords(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listScans(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listScans(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSparePartImages(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSparePartImages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [lastEvaluatedKey] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listTools(lastEvaluatedKey?: string, limit?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listTools(lastEvaluatedKey, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a Ede
     * @param {string} edeId 
     * @param {UpdateEdeRequestContent} updateEdeRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateEde(edeId: string, updateEdeRequestContent: UpdateEdeRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateEde(edeId, updateEdeRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a Level1 Pic
     * @param {string} level1PicUuid Level1 Pic identifier
     * @param {UpdateLevel1PicRequestContent} updateLevel1PicRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateLevel1Pic(level1PicUuid: string, updateLevel1PicRequestContent: UpdateLevel1PicRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateLevel1Pic(level1PicUuid, updateLevel1PicRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a Pet
     * @param {string} petId Pet identifier
     * @param {UpdatePetRequestContent} updatePetRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updatePet(petId: string, updatePetRequestContent: UpdatePetRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updatePet(petId, updatePetRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} recordId 
     * @param {UpdateRoomAccessRecordRequestContent} updateRoomAccessRecordRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateRoomAccessRecord(recordId: string, updateRoomAccessRecordRequestContent: UpdateRoomAccessRecordRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateRoomAccessRecord(recordId, updateRoomAccessRecordRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a Scan
     * @param {string} scanId 
     * @param {UpdateScanRequestContent} updateScanRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateScan(scanId: string, updateScanRequestContent: UpdateScanRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateScan(scanId, updateScanRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} model 
     * @param {string} type 
     * @param {UpdateSparePartImageRequestContent} updateSparePartImageRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSparePartImage(model: string, type: string, updateSparePartImageRequestContent: UpdateSparePartImageRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateSparePartImage(model, type, updateSparePartImageRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {UpdateToolRequestContent} updateToolRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateTool(uuid: string, updateToolRequestContent: UpdateToolRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateTool(uuid, updateToolRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UploadFileRequestContent} uploadFileRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadFile(uploadFileRequestContent: UploadFileRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).uploadFile(uploadFileRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UploadSparePartImageRequestContent} uploadSparePartImageRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadSparePartImage(uploadSparePartImageRequestContent: UploadSparePartImageRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).uploadSparePartImage(uploadSparePartImageRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the Identity of the API caller
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public whoAmI(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).whoAmI(options).then((request) => request(this.axios, this.basePath));
    }
}

