import * as React from "react";
import { useState, useEffect } from 'react';
import {
    Table,
    Button,
    SpaceBetween,
    Container,
    Header,
    Box,
    Modal,
    FormField,
    Select
} from "@amzn/awsui-components-react";
import ApiFactory from '../../open-api/ApiFactory';

interface PendingRecord {
    recordId: string;
    operator: string;
    securityGuard: string;
    accessType: string;
    roomInfo: string;
    status: string;
    tickets: string;
    serialNumberOrModel?: string; // 使用可选属性
    checkTime: string;
    checkResult: string;
}

export default () => {

    const [records, setRecords] = useState<PendingRecord[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedRecord, setSelectedRecord] = useState<PendingRecord | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [reviewDecision, setReviewDecision] = useState<string | null>(null);

    const Api = ApiFactory();

    useEffect(() => {
        fetchPendingRecords();
    }, []);

    const fetchPendingRecords = async () => {
        setIsLoading(true);
        try {
            const response = await Api.listPendingReviewRecords();
            const formattedRecords: PendingRecord[] = response.data.records.map((record: any) => ({
                ...record,
                serialNumberOrModel: record.serialNumberOrModel || "-" // 如果为 undefined，设置为 "-"
            }));
            setRecords(formattedRecords);
        } catch (error) {
            console.error("Error fetching pending records:", error);
        }
        setIsLoading(false);
    };

    const handleReviewClick = (record: PendingRecord) => {
        setSelectedRecord(record);
        setIsModalVisible(true);
    };

    const handleReviewSubmit = async () => {
        if (!selectedRecord || !reviewDecision) return;

        try {
            await Api.updateRoomAccessRecord(selectedRecord.recordId, {
                checkResult: reviewDecision
            });
            setIsModalVisible(false);
            fetchPendingRecords(); // Refresh the list
        } catch (error) {
            console.error("Error updating record:", error);
        }
    };

    const columnDefinitions = [
        { header: "操作人员", cell: (item: PendingRecord) => item.operator },
        { header: "安保人员", cell: (item: PendingRecord) => item.securityGuard },
        { header: "进出类型", cell: (item: PendingRecord) => item.accessType },
        { header: "房间信息", cell: (item: PendingRecord) => item.roomInfo },
        { header: "进出状态", cell: (item: PendingRecord) => item.status },
        {
            header: "设备型号/序列号",
            cell: (item: PendingRecord) => item.serialNumberOrModel
        },
        { header: "检查时间", cell: (item: PendingRecord) => item.checkTime },
        {
            header: "当前状态",
            cell: (item: PendingRecord) => {
                const status = item.checkResult.startsWith('PA') ? '待审核（允许）' : '待审核（拒绝）';
                return <Box color="text-status-warning">{status}</Box>;
            }
        },
        {
            header: "操作",
            cell: (item: PendingRecord) => (
                <Button onClick={() => handleReviewClick(item)}>审核</Button>
            )
        },
    ];

    return (
        <Container header={<Header variant="h1">待审核记录</Header>}>
            <Table
                columnDefinitions={columnDefinitions}
                items={records}
                loading={isLoading}
                loadingText="加载记录中"
                empty={
                    <Box textAlign="center" color="inherit">
                        <b>无待审核记录</b>
                        <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                            当前没有需要审核的记录。
                        </Box>
                    </Box>
                }
            />

            <Modal
                onDismiss={() => setIsModalVisible(false)}
                visible={isModalVisible}
                header="审核记录"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setIsModalVisible(false)}>取消</Button>
                            <Button variant="primary" onClick={handleReviewSubmit}>提交审核</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                {selectedRecord && (
                    <SpaceBetween direction="vertical" size="l">
                        <FormField label="操作人员">
                            <Box>{selectedRecord.operator}</Box>
                        </FormField>
                        <FormField label="进出类型">
                            <Box>{selectedRecord.accessType}</Box>
                        </FormField>
                        <FormField label="房间信息">
                            <Box>{selectedRecord.roomInfo}</Box>
                        </FormField>
                        <FormField label="设备型号/序列号">
                            <Box>{selectedRecord.serialNumberOrModel}</Box>
                        </FormField>
                        <FormField label="当前状态">
                            <Box>{selectedRecord.checkResult}</Box>
                        </FormField>
                        <FormField label="审核决定">
                            <Select
                                selectedOption={reviewDecision ? { label: reviewDecision, value: reviewDecision } : null}
                                onChange={({ detail }) => setReviewDecision(detail.selectedOption.value as string)}
                                options={[
                                    { label: "批准", value: "APPROVED" },
                                    { label: "拒绝", value: "REJECTED" }
                                ]}
                                placeholder="选择审核结果"
                            />
                        </FormField>
                    </SpaceBetween>
                )}
            </Modal>
        </Container>
    );
}