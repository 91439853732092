import * as React from "react";
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input, { InputProps } from "@amzn/awsui-components-react/polaris/input";
import { AttributeEditor, Autosuggest, AutosuggestProps, ColumnLayout, RadioGroup } from "@amzn/awsui-components-react";
import useStateRef from "react-usestateref";
import { useEffect, useRef, useState } from "react";
import ApiFactory from '../../open-api/ApiFactory';
import { Ede, Pet, Scan, Tool } from "../../open-api/generated-src";
import { getUser } from "../../auth/User";
import Table from "@amzn/awsui-components-react/polaris/table";
import { COLUMN_DEFINITIONS, getMatchesCountText, paginationLabels } from "../partsPage/table-config";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { cicoNotes, cicoStatus, mitigationsStatus } from "../Helper";
import Box from "@amzn/awsui-components-react/polaris/box";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Markdown from "react-markdown";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import * as moment from "moment";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import { PhoneToolImg } from "../Helper";


const a: Array<Scan> = []

export default () => {
    const [statusData, setStatusData] = useState(<p>Pending</p>);
    const [user, setUser] = useState('');
    const [iovalue, setIOValue] = React.useState("IN");
    const [sealitems, setSealItems] = React.useState<any>([]);
    const [noteData, setNoteData] = useState(<p>Pending</p>);
    const [appData, setAppData] = useState(<p>Pending</p>);
    const [ssealData, setSsealData] = useState(<p>Pending</p>);
    const [mitigationStatus, setMitigationStatus] = useState(<p>Pending</p>);
    const [tpvr, setTPVRData] = useState('');
    const [isCatTool, setIsCatTool] = useState(false);
    const [catToolData, setCatToolData] = useState<Tool | null>(null);
    const [showOperatorImage, setShowOperatorImage] = useState(false);
    const operatorInputRef = useRef<HTMLInputElement>(null);

    
    useEffect(() => {
        (async () => {
            try {
                setUser(await getUser());
            }
            catch (error: any) {
                setUser('Unknown User')
            }
        })();
    }, []);

    const initialScanData: Scan = {
        type: 'Tools',
        guard_login: '',
        bb_login: "",
        room: "",
        scanId: "123",
        scan_deta: "123",
        sn: "",
        tickets: "",
        status: ""
    };

    const initialEdeData: Ede = {
        model: "Loading",
        category: "Loading",
        maker: "Loading",
        notes: "Loading",
        status: "Loading",
        updateat: "Loading",
        updateby: "Loading",
        approvedby: "Loading",
        edeId: "Loading"
    };

    const [edeData, setEdeData] = useState(initialEdeData);
    const [srcUrl, setSrcUrl] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const [allTools, setAllTools] = useState<Tool[]>([]);

    useEffect(() => {
        fetchAllTools();
    }, []);

    const fetchAllTools = async () => {
        setIsLoading(true);
        try {
            const response = await ScansApi.listTools(undefined, 800);
            setAllTools(response.data.tools);
        } catch (error) {
            console.error("Error fetching tools:", error);
        } finally {
            setIsLoading(false);
        }
    };

    async function turnOnModal(test: string) {
        setEdeData(initialEdeData);
        setIsCatTool(false);
        setCatToolData(null);
        setErrorText('');
        setStatusData(<p>Pending</p>);
        setNoteData(<p>Pending</p>);
        setAppData(<p>Pending</p>);
        setSsealData(<p>Pending</p>);
        setMitigationStatus(<p>Pending</p>);
        setSrcUrl('');

        // 首先在已获取的工具列表中查找匹配的工具
        const matchedTool = allTools.find(tool =>

            (tool.modelNumber.toLowerCase() === test.toLowerCase() ||
                tool.category.toLowerCase() === test.toLowerCase() ||
                tool.maker.toLowerCase() === test.toLowerCase()) && tool.approved === "Approved"
        );

        console.log(test.toLowerCase())

        if (matchedTool) {
            setIsCatTool(true);
            setCatToolData(matchedTool);
            setVisible(true);
            return;
        }

        // 如果在工具列表中没有找到，继续检查是否为 EDE
        try {
            const edeDate = (await ScansApi.getEde(test)).data.ede as Ede;
            setEdeData(edeDate);
            console.log(edeDate.status);
            if (edeDate.status.indexOf('CICO') != -1) {
                setNoteData(cicoNotes(edeDate.notes, 'notes'));
                setAppData(cicoNotes(edeDate.notes, 'approve'));
                setSsealData(cicoNotes(edeDate.notes, 'seal'));
                const str = edeDate.status.split('- S:', 2)[1];
                const start = new Date(str.split('=E:', 2)[0]).getTime() / 1000;
                const end = new Date(str.split('=E:', 2)[1]).getTime() / 1000;

                const approve_seal = edeDate.notes.split('&&Approve ID&&', 2)[1];
                const seal = approve_seal.split('&&Seal&&', 2)[1];
                console.log(seal);

                sealitems.sort();
                console.log(sealitems as string);

                const date = new Date().getTime() / 1000;
                console.log(start - date);// 复数
                console.log(end - date);// 正数
                if ((start - date) <= 0 && (end - date) >= 0 && sealitems as string == seal as string) {
                    setMitigationStatus(mitigationsStatus(edeDate.status) as JSX.Element);
                    setVisibleexce(true);
                    console.log(start - date);// 复数
                    console.log(end - date);// 正数
                }
                else {
                    setErrorText('CICO Exception过期或安防标签不匹配！');
                }
            }
            else {
                setVisible(true);
            }

            setStatusData(cicoStatus(edeData.status));
            const edeid = edeDate.edeId + '.png' as string;
            const urlLink = await ScansApi.getPic(edeid).then(async res => {
                console.log(res.data);
                setSrcUrl(res.data as string);
            });
        } catch (error) {
            console.error("Error fetching CAT:", error);
            setErrorText('CAT中未找到已批复的本类型工具！');
        }
    }

    const [loading, setLoading] = useState(false);
    const [footer, setFooter] = useState('');
    const [scanData, setScanData] = useState(initialScanData);
    const ScansApi = ApiFactory();

    const onChange = (attribute, value) => {
        const newState = { ...scanData };
        newState[attribute] = value;
        setScanData(newState);
    };
    const [errorText, setErrorText] = useState('');

    const [inputLoginDisabled, setInputLoginDisabled] = useStateRef(false);
    const [inputRoomDisabled, setInputRoomDisabled] = useStateRef(false);

    const onKeyDownLogin = (e: any) => {
        if (e.detail.keyCode === 13) {
            setInputLoginDisabled(true);
        }
    }

    const [visible, setVisible] = React.useState(false);
    const [visibleexce, setVisibleexce] = React.useState(false);

    async function save() {
        try {
            setErrorText('');
            if (scanData.bb_login && scanData.room && scanData.sn) {
                if (!scanData.status) {
                    const edeResponse = await ScansApi.edeGet(scanData.sn);
                    if (edeResponse.data as string !== 'Not in EDE/Exception') {
                        if(edeResponse.data as string  !== 'Not in EDE'){
                            await turnOnModal(edeResponse.data as string);
                        } else {
                            await turnOnModal(scanData.sn);
                        }
                    } else {
                        setErrorText('Not in CAT/Exception');
                    }
                } else {
                    scanData.room = scanData.room + iovalue;
                    scanData.guard_login = user;
                    
                    const response = await ScansApi.createScan(scanData);
                    const newItem = response.data.created_scan;
                    
                    // 更新本地数据，避免完全重新加载
                    setAllItems(prevItems => [newItem, ...(prevItems || [])]);
                    
                    // 重置表单数据
                    setScanData(prev => ({
                        ...prev,
                        sn: '',
                        tickets: '',
                        status: ''
                    }));
                    
                    // 重置其他相关状态
                    setSealItems([]);
                    setTPVRData('');
                    setInputLoginDisabled(false);
                    setInputRoomDisabled(false);
                    setShowOperatorImage(false);
                    
                    // 聚焦到 Login 输入框
                    if (operatorInputRef.current) {
                        operatorInputRef.current.focus();
                    }
                }
            } else {
                setErrorText('请输入人员信息，房间信息以及备件信息');
            }
        } catch (error: any) {
            setErrorText(error?.response?.data?.message || 'Unknown error');
        }
    }

    const [allItems, setAllItems] = useStateRef<Scan[]>();

    const onKeyDownRoom = (e: any) => {
        if (e.detail.keyCode === 13) {
            setInputRoomDisabled(true);
        }
    }

    const onKeyDownSN = (e: any) => {
        if (e.detail.keyCode === 13) {
            if (scanData.bb_login != '' && scanData.room != '' && scanData.sn != '') {
                setErrorText('');
                ScansApi.edeGet(scanData.sn).then(async res => {
                    console.log(res.data);
                    if (res.data as string != 'Not in EDE/Exception') {
                        if (res.data as string != 'Not in EDE') {
                            await turnOnModal(res.data as string);
                        }
                        else {
                            await turnOnModal(scanData.sn);
                        }
                    } else {
                        setErrorText('Not in CAT/Exception');
                    }
                });
            }
            else {
                setErrorText('请输入人员信息，房间信息以及备件信息');
            }
        }
    }

    const goodToIn = async () => {
        setVisible(false);
        scanData.status = "允许进入";
        await save();
    };
    
    const badToIn = async () => {
        setVisible(false);
        scanData.status = "拒绝进入";
        await save();
    };

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        allItems || [],
        {
            filtering: {
            },
            pagination: {
                pageSize: 50
            },
            sorting: {
                defaultState: {
                    sortingColumn: COLUMN_DEFINITIONS[6],
                    isDescending: true,
                }
            },
            selection: {}
        }
    );

    const { selectedItems } = collectionProps;

    if (isLoading) {
        return (
            <Box textAlign="center" padding={{ top: "xxxl" }}>
                <Spinner size="large" />
                <Box variant="h2" padding={{ top: "m" }}>
                    同步中...
                </Box>
            </Box>
        );
    }

    return (
        <React.Fragment>
            <span>
                <form onSubmit={e => e.preventDefault()}>
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button formAction="none" variant="link">
                                    Cancel
                                </Button>
                                <Button variant="primary" onClick={save}>Submit</Button>
                            </SpaceBetween>
                        }
                        header={<>
                            <Header variant="h1">工具检查</Header>
                            <Header variant="h3">安防： {user}</Header>
                        </>}
                        errorText={errorText}
                    >
                        <Container
                            header={
                                <Header variant="h2">
                                    输入工具及人员信息
                                </Header>
                            }
                        >
                            <SpaceBetween direction="vertical" size="l">
                                <ColumnLayout columns={3}>
                                    <FormField label={
                                        <span>
                                            <span>
                                                步骤 1 - 输入用户Login
                                                {showOperatorImage && scanData.bb_login && (
                                                    <span style={{ marginLeft: '8px' }}>
                                                        {PhoneToolImg(scanData.bb_login)}
                                                    </span>
                                                )}
                                            </span>
                                            <span style={{ float: 'right' }}>
                                                <Button
                                                    iconName='edit'
                                                    onClick={() => {
                                                        setInputLoginDisabled(false)
                                                    }}
                                                />
                                            </span>
                                        </span>
                                    } description='Operator Alias'>
                                        <Input
                                            value={scanData.bb_login}
                                            placeholder='login'
                                            onChange={({ detail: { value } }) => {
                                                onChange('bb_login', value);
                                                setShowOperatorImage(false);
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.detail.keyCode === 13) {
                                                    setInputLoginDisabled(true);
                                                    setShowOperatorImage(true);
                                                }
                                            }}
                                            disabled={inputLoginDisabled}
                                        />
                                    </FormField>
                                    <FormField label={
                                        <span>
                                            <span>
                                                步骤 2 -扫描房间
                                            </span>
                                            <span style={{ float: 'right' }}>
                                                <Button
                                                    iconName='edit'
                                                    onClick={() => {
                                                        setInputRoomDisabled(false)
                                                    }}
                                                />
                                            </span>
                                        </span>
                                    } description='Red Zone Location'>
                                        <Input
                                            value={scanData.room}
                                            placeholder='XX.XX'
                                            onChange={({ detail: { value } }) => onChange('room', value)}
                                            onKeyDown={(e) => onKeyDownRoom(e)}
                                            disabled={inputRoomDisabled}
                                        />
                                        <RadioGroup
                                            onChange={({ detail }) => setIOValue(detail.value)}
                                            value={iovalue}
                                            items={[
                                                { value: "IN", label: "IN" },
                                                { value: "OUT", label: "OUT" },
                                            ]}
                                        />
                                    </FormField>
                                    <FormField label={
                                        <span>
                                            步骤 3 - 扫描设备序列号或型号
                                        </span>
                                    } description='Serial.No/Identifier/CATModel.No'>
                                        <Input
                                            value={scanData.sn}
                                            placeholder='SN'
                                            onChange={({ detail: { value } }) => onChange('sn', value)}
                                            onKeyDown={(e) => onKeyDownSN(e)}
                                        />
                                    </FormField>
                                    <FormField label={
                                        <span>
                                            步骤 4 - 输入TT号（选填）
                                        </span>
                                    } description='Related ticket'>
                                        <Input
                                            value={scanData.tickets as string}
                                            placeholder='ticket'
                                            onChange={({ detail: { value } }) => onChange('tickets', value)}
                                        />
                                    </FormField>
                                    <FormField label={
                                        <span>
                                            步骤 5 - 封签号（选填）
                                        </span>
                                    } description='Related ticket'>
                                        <AttributeEditor
                                            onRemoveButtonClick={({
                                                detail: { itemIndex }
                                            }) => {
                                                const tmpItems = [...sealitems];
                                                tmpItems.splice(itemIndex, 1);
                                                setSealItems(tmpItems);
                                            }}
                                            items={sealitems}
                                            addButtonText="添加标签"
                                            definition={[
                                                {
                                                    label: "Seal",
                                                    control: (item, index) => (
                                                        <Input
                                                            value={sealitems[index]}
                                                            placeholder="请输入标签"
                                                            onChange={({ detail }) => {
                                                                const tmpItems = [...sealitems];
                                                                tmpItems[index] = detail.value
                                                                setSealItems(tmpItems)
                                                            }}
                                                            autoFocus
                                                        />
                                                    )
                                                }
                                            ]}
                                            onAddButtonClick={() => setSealItems([...sealitems, ""])}
                                            empty="无安防标签"
                                            removeButtonText="删除"
                                        />
                                    </FormField>
                                    <FormField label={
                                        <span>
                                            步骤 6 - 携带人/陪同人（选填）
                                        </span>
                                    } description='Related ticket'>
                                        <Input
                                            value={tpvr}
                                            placeholder='BB login'
                                            onChange={({ detail: { value } }) => setTPVRData(value)}
                                        />
                                    </FormField>
                                </ColumnLayout>
                            </SpaceBetween>
                        </Container>
                    </Form>
                </form>
            </span>

            <hr />
            <span>
                <Table
                    {...collectionProps}
                    loading={loading}
                    loadingText="Loading instances"
                    selectionType="single"
                    footer={footer}
                    header={
                        <Header
                            counter={
                                allItems &&
                                (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
                            }
                        >
                            Records
                        </Header>
                    }
                    columnDefinitions={COLUMN_DEFINITIONS}
                    items={items}
                    sortingColumn={COLUMN_DEFINITIONS[6]}
                    pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
                    filter={
                        <TextFilter
                            {...filterProps}
                            countText={getMatchesCountText(filteredItemsCount!)}
                            filteringAriaLabel="Filter records"
                        />
                    }
                />
            </span>

            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                size={"large"}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => badToIn()}>拒绝进入</Button>
                            <Button variant="primary" onClick={() => goodToIn()}>允许进入</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="查看详情"
            >
                <Grid
                    gridDefinition={[
                        { colspan: { xl: 3, l: 3, s: 3, xxs: 3 } },
                        { colspan: { xl: 8, l: 8, s: 8, xxs: 8 } }
                    ]}
                >
                    <div>
                        <SpaceBetween direction="vertical" size="l">
                            <Box>
                                <FormField label="类别">
                                    <p>{isCatTool ? catToolData?.category : edeData.category}</p>
                                </FormField>
                            </Box>
                            <Box>
                                <FormField label="厂商">
                                    <p>{isCatTool ? catToolData?.maker : edeData.maker}</p>
                                </FormField>
                            </Box>
                            <Box>
                                <FormField label="型号">
                                    <p>{isCatTool ? catToolData?.modelNumber : edeData.model}</p>
                                </FormField>
                            </Box>
                            {isCatTool ? (
                                <>
                                    <Box>
                                        <FormField label="定义">
                                            <p>{catToolData?.definitions}</p>
                                        </FormField>
                                    </Box>
                                    <Box>
                                        <FormField label="缓解措施">
                                            <p>{catToolData?.mitigations}</p>
                                        </FormField>
                                    </Box>
                                    <Box>
                                        <FormField label="注意事项">
                                            <p>{catToolData?.notes}</p>
                                        </FormField>
                                    </Box>
                                    <Box>
                                        <FormField label="批准状态">
                                            <p>{catToolData?.approved}</p>
                                        </FormField>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box>
                                        <FormField label="管控措施">
                                            {mitigationStatus}
                                        </FormField>
                                    </Box>
                                    <Box>
                                        <FormField label="状态">
                                            {statusData}
                                        </FormField>
                                    </Box>
                                    <Box>
                                        <FormField label="注意">
                                            <Markdown>{edeData.notes}</Markdown>
                                        </FormField>
                                    </Box>
                                </>
                            )}
                        </SpaceBetween>
                    </div>
                    <div>
                        <SpaceBetween direction="horizontal" size="l">
                            <Box
                                margin={{ left: "l" }}
                                padding={{ left: "l" }}
                                float="right">
                                <img
                                    src={isCatTool ? catToolData?.imageUrl : srcUrl}
                                    width={"100%"}
                                />
                            </Box>
                        </SpaceBetween>
                    </div>
                </Grid>
            </Modal>

            <Modal
                onDismiss={() => setVisibleexce(false)}
                visible={visibleexce}
                size={"large"}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => badToIn()}>拒绝进入</Button>
                            <Button variant="primary" onClick={() => goodToIn()}>允许进入</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="查看详情"
            >
                <Grid
                    gridDefinition={[
                        { colspan: { xl: 3, l: 3, s: 3, xxs: 3 } },
                        { colspan: { xl: 8, l: 8, s: 8, xxs: 8 } }
                    ]}
                >
                    <div>
                        <SpaceBetween direction="vertical" size="l">
                            <Box>
                                <FormField label="类别">
                                    <p>{edeData.category}</p>
                                </FormField>
                            </Box>
                            <Box>
                                <FormField label="厂商">
                                    <p>{edeData.maker}</p>
                                </FormField>
                            </Box>
                            <Box>
                                <FormField label="型号">
                                    {<p>{edeData.model}</p>}
                                </FormField>
                            </Box>
                            <Box>
                                <FormField label="注意">
                                    {noteData}
                                </FormField>
                            </Box>
                            <Box>
                                <FormField label="状态">
                                    {statusData}
                                </FormField>
                            </Box>
                            <Box>
                                <FormField label="Approve ID">
                                    {appData}
                                </FormField>
                            </Box>
                            <Box>
                                <FormField label="封签号">
                                    {ssealData}
                                </FormField>
                            </Box>
                        </SpaceBetween>
                    </div>
                    <div>
                        <SpaceBetween direction="horizontal" size="l">
                            <Box
                                margin={{ left: "l" }}
                                padding={{ left: "l" }}
                                float="right">
                                <img
                                    src={srcUrl}
                                    width={"100%"}
                                />
                            </Box>
                        </SpaceBetween>
                    </div>
                </Grid>
            </Modal>
        </React.Fragment>
    );
}